/*!* ArticleCreationStyles.css *!*/

/*.article-creation-container {*/
/*    padding: 2rem;*/
/*    min-height: 100vh;*/
/*}*/

/*.article-creation-card {*/
/*    border: none;*/
/*    border-radius: 0.75rem;*/
/*    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);*/
/*}*/

/*.article-creation-header {*/
/*    background-color: #212529;*/
/*    padding: 1.25rem 1.5rem;*/
/*    border-top-left-radius: 0.75rem;*/
/*    border-top-right-radius: 0.75rem;*/
/*}*/

/*.article-creation-header-label {*/
/*    color: #fff;*/
/*    font-size: 1.125rem;*/
/*    font-weight: 500;*/
/*    letter-spacing: 0.5px;*/
/*}*/

/*.article-creation-body {*/
/*    padding: 2rem;*/
/*    background-color: #fff;*/
/*}*/

/*.article-creation-alert {*/
/*    margin-bottom: 1.5rem;*/
/*}*/

/*.article-creation-label {*/
/*    color: #212529;*/
/*    font-weight: 500;*/
/*    margin-bottom: 0.5rem;*/
/*    font-size: 0.9375rem;*/
/*}*/

/*.article-creation-input,*/
/*.article-creation-select,*/
/*.article-creation-textarea {*/
/*    border: 1px solid #dee2e6;*/
/*    border-radius: 0.5rem;*/
/*    padding: 0.625rem 0.875rem;*/
/*    font-size: 0.9375rem;*/
/*    transition: all 0.15s ease-in-out;*/
/*    background-color: #fff;*/
/*}*/

/*.article-creation-input:focus,*/
/*.article-creation-select:focus,*/
/*.article-creation-textarea:focus {*/
/*    border-color: #212529;*/
/*    box-shadow: 0 0 0 0.15rem rgba(33, 37, 41, 0.25);*/
/*}*/

/*.article-creation-textarea {*/
/*    resize: vertical;*/
/*    min-height: 100px;*/
/*}*/

/*.article-creation-divider {*/
/*    position: relative;*/
/*    text-align: center;*/
/*    margin: 2.5rem 0;*/
/*    border-bottom: 1px solid #dee2e6;*/
/*}*/

/*.article-creation-divider-text {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 1rem;*/
/*    transform: translateY(-50%);*/
/*    background-color: #fff;*/
/*    padding: 0 1rem;*/
/*    color: #6c757d;*/
/*    font-size: 0.875rem;*/
/*    font-weight: 500;*/
/*    text-transform: uppercase;*/
/*    letter-spacing: 0.5px;*/
/*}*/

/*.article-creation-footer {*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    margin-top: 2.5rem;*/
/*    padding-top: 1.5rem;*/
/*    border-top: 1px solid #dee2e6;*/
/*}*/

/*.article-creation-submit {*/
/*    min-width: 140px;*/
/*    padding: 0.625rem 1.25rem;*/
/*    font-weight: 500;*/
/*    letter-spacing: 0.3px;*/
/*    transition: all 0.15s ease-in-out;*/
/*}*/

/*.article-creation-submit:not(:disabled):hover {*/
/*    background-color: #000;*/
/*}*/

/*.article-creation-submit:disabled {*/
/*    background-color: #6c757d;*/
/*    border-color: #6c757d;*/
/*}*/

/*@media (max-width: 992px) {*/
/*    .article-creation-container {*/
/*        padding: 1rem;*/
/*    }*/

/*    .article-creation-body {*/
/*        padding: 1.5rem;*/
/*    }*/
/*}*/


.article-creation-container {
    padding: 2rem 1rem;
    min-height: 100vh;
}

.article-creation-toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
}

.article-creation-toast {
    min-width: 250px;
}

.article-creation-card {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
}

.article-creation-header {
    padding: 1.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.article-creation-title {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.article-creation-body {
    padding: 2rem;
}

.article-creation-section {
    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e9ecef;
}

.article-creation-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.article-creation-section-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #212529;
    margin-bottom: 1.5rem;
}

.article-creation-field-label {
    font-size: 0.9rem;
    font-weight: 500;
    color: #495057;
    margin-bottom: 0.5rem;
}

.article-creation-text-input,
.article-creation-binary-select,
.article-creation-textarea {
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    padding: 0.625rem 0.875rem;
    font-size: 0.95rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.article-creation-text-input:focus,
.article-creation-binary-select:focus,
.article-creation-textarea:focus {
    border-color: #212529;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.25);
}

.article-creation-textarea {
    min-height: 120px;
    resize: vertical;
}

.article-creation-image-upload {
    background-color: #f8f9fa;
    border: 2px dashed #dee2e6;
    border-radius: 0.5rem;
    padding: 1.5rem;
    text-align: center;
    transition: border-color 0.15s ease-in-out;
}

.article-creation-image-upload:hover {
    border-color: #adb5bd;
}

.article-creation-file-input {
    margin-bottom: 1rem;
}

.article-creation-preview {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.article-creation-preview-image {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    border-radius: 0.375rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.article-creation-select .article-creation-select__control {
    border-color: #dee2e6;
    border-radius: 0.375rem;
    min-height: 42px;
}

.article-creation-select .article-creation-select__control:hover {
    border-color: #adb5bd;
}

.article-creation-select .article-creation-select__control--is-focused {
    border-color: #212529;
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.25);
}

.article-creation-select .article-creation-select__menu {
    border-radius: 0.375rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.article-creation-select .article-creation-select__option--is-focused {
    background-color: #f8f9fa;
}

.article-creation-select .article-creation-select__option--is-selected {
    background-color: #212529;
}

.article-creation-select .article-creation-select__multi-value {
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.article-creation-select .article-creation-select__multi-value__remove:hover {
    background-color: #dc3545;
    color: white;
}

.article-creation-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    border-top: 1px solid #e9ecef;
}

.article-creation-submit-btn {
    min-width: 140px;
    padding: 0.625rem 1.25rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    transition: all 0.15s ease-in-out;
}

.article-creation-submit-btn:not(:disabled):hover {
    background-color: #000;
}

.article-creation-submit-btn:disabled {
    background-color: #495057;
    border-color: #495057;
}

@media (max-width: 768px) {
    .article-creation-container {
        padding: 1rem 0.5rem;
    }

    .article-creation-body {
        padding: 1.5rem 1rem;
    }

    .article-creation-section {
        margin-bottom: 2rem;
        padding-bottom: 1.5rem;
    }

    .article-creation-toast-container {
        top: 10px;
        right: 10px;
        left: 10px;
    }

    .article-creation-toast {
        width: 100%;
    }
}



