.terms-of-service {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 3%;
}

.terms-of-service h1,
.terms-of-service h2 {
    margin-top: 20px;
    color: #333;
}

.terms-of-service h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
}

.terms-of-service h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.terms-of-service p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.terms-of-service h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.terms-of-service p strong {
    font-weight: bold;
}

.terms-of-service .text-center {
    text-align: center;
}

@media (max-width: 768px) {
    .terms-of-service h1 {
        font-size: 2rem;
    }

    .terms-of-service h2 {
        font-size: 1.3rem;
    }

    .terms-of-service p {
        font-size: 0.9rem;
    }

    .terms-of-service h3 {
        font-size: 1.1rem;
    }
}
