/*.sealab-finances {*/
/*    background-color: #f8f9fa;*/
/*    min-height: 100vh;*/
/*}*/

/*.sealab-finances-title {*/
/*    color: #2c3e50;*/
/*    font-weight: 600;*/
/*}*/

/*.sealab-finances-subtitle {*/
/*    color: #6c757d;*/
/*}*/

/*.sealab-finances-control {*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.sealab-finances-card {*/
/*    height: 100%;*/
/*    border: none;*/
/*    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);*/
/*    transition: transform 0.2s ease-in-out;*/
/*}*/

/*.sealab-finances-card:hover {*/
/*    transform: translateY(-3px);*/
/*}*/

/*.sealab-finances-card-icon {*/
/*    font-size: 1.75rem;*/
/*    color: #0d6efd;*/
/*    margin-bottom: 0.75rem;*/
/*}*/

/*.sealab-finances-card-title {*/
/*    color: #6c757d;*/
/*    font-weight: 500;*/
/*    margin-bottom: 0.5rem;*/
/*}*/

/*.sealab-finances-card-value {*/
/*    color: #2c3e50;*/
/*    font-weight: 600;*/
/*    margin-bottom: 0;*/
/*}*/

/*.sealab-finances-card-text {*/
/*    font-size: 0.9rem;*/
/*    margin: 0.25rem 0;*/
/*    font-weight: bold;*/
/*}*/

/*.sealab-finances-table-card {*/
/*    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);*/
/*    border: none;*/
/*}*/

/*.sealab-finances-table-header {*/
/*    background-color: #fff;*/
/*    border-bottom: 1px solid #e9ecef;*/
/*}*/

/*.sealab-finances-table th {*/
/*    font-weight: 600;*/
/*    color: #495057;*/
/*}*/

/*.sealab-finances-status {*/
/*    padding: 0.25rem 0.5rem;*/
/*    border-radius: 0.25rem;*/
/*    font-size: 0.875rem;*/
/*    font-weight: 500;*/
/*}*/

/*.sealab-finances-status.completed {*/
/*    background-color: #d1e7dd;*/
/*    color: #0f5132;*/
/*}*/

/*.sealab-finances-status.pending {*/
/*    background-color: #fff3cd;*/
/*    color: #856404;*/
/*}*/

/*.sealab-finances-action-btn {*/
/*    white-space: nowrap;*/
/*}*/

/*.sealab-finances-search:focus,*/
/*.sealab-finances-select:focus {*/
/*    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);*/
/*    border-color: #86b7fe;*/
/*}*/

/*!* Edit Panel Styles *!*/
/*.vpo-edit-panel {*/
/*    display: grid;*/
/*    grid-template-columns: 1fr;*/
/*    gap: 1.5rem;*/
/*    background: #f9fafb;*/
/*    padding: 1.5rem;*/
/*    border-radius: 0.5rem;*/
/*    margin-bottom: 2rem;*/
/*}*/

/*.test3 {*/
/*    margin-bottom: 15px;*/
/*}*/

/*.vpo-edit-row {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));*/
/*    gap: 1.5rem;*/
/*}*/

/*.vpo-edit-group {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 0.5rem;*/
/*}*/

/*.vpo-edit-group label {*/
/*    font-size: 0.75rem;*/
/*    font-weight: 500;*/
/*    text-transform: uppercase;*/
/*    color: #374151;*/
/*    letter-spacing: 0.05em;*/
/*}*/

/*.vpo-edit-group.full-width {*/
/*    grid-column: 1 / -1;*/
/*    width: 100%;*/
/*    max-width: 100%;*/
/*    margin: 0 auto;*/
/*}*/

/*.save-btn-group {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*!* Input Field Style *!*/
/*.vpo-input {*/
/*    padding: 0.5rem 0.75rem;*/
/*    border: 1px solid #e5e7eb;*/
/*    border-radius: 0.375rem;*/
/*    font-size: 0.875rem;*/
/*    color: #111827;*/
/*    background: #fff;*/
/*}*/

/*.vpo-input:focus {*/
/*    outline: none;*/
/*    border-color: #2563eb;*/
/*    box-shadow: 0 0 0 1px #2563eb;*/
/*}*/

/*!* Notes Field *!*/
/*.vpo-notes-group {*/
/*    grid-column: 1 / -1;*/
/*    margin-top: 1rem;*/
/*}*/

/*.vpo-notes-field {*/
/*    width: 100%;*/
/*    padding: 0.75rem;*/
/*    border: 1px solid #e5e7eb;*/
/*    border-radius: 0.375rem;*/
/*    font-size: 0.875rem;*/
/*    color: #111827;*/
/*    resize: vertical;*/
/*    min-height: 100px;*/
/*    font-family: inherit;*/
/*    background: white;*/
/*}*/

/*.vpo-notes-field:focus {*/
/*    outline: none;*/
/*    border-color: #808080;*/
/*    box-shadow: 0 0 0 1px #808080;*/
/*}*/

/*.vpo-notes-field::placeholder {*/
/*    color: #9ca3af;*/
/*}*/

/*!* Responsive Design *!*/
/*@media (max-width: 1024px) {*/
/*    .sealab-finances {*/
/*        padding: 1.5rem;*/
/*    }*/

/*    .vpo-edit-panel {*/
/*        grid-template-columns: 1fr;*/
/*    }*/

/*    .save-btn-group {*/
/*        margin-top: 1rem;*/
/*    }*/
/*}*/

/*@media (max-width: 768px) {*/
/*    .sealab-finances {*/
/*        padding: 1rem;*/
/*    }*/

/*    .vpo-edit-panel {*/
/*        grid-template-columns: 1fr;*/
/*    }*/

/*    .save-btn-group {*/
/*        margin-top: 1rem;*/
/*    }*/

/*    .sealab-finances-table {*/
/*        grid-template-columns: 1fr;*/
/*    }*/
/*}*/

/*!* Print Styles *!*/
/*@media print {*/
/*    .sealab-finances {*/
/*        padding: 0;*/
/*    }*/

/*    .vpo-edit-panel {*/
/*        display: none;*/
/*    }*/

/*    .vpo-card {*/
/*        break-inside: avoid;*/
/*    }*/
/*}*/


.sealab-finances {
    background-color: #f8f9fa;
    min-height: 100vh;
}

.sealab-finances-title {
    color: #2c3e50;
    font-weight: 600;
}

.sealab-finances-subtitle {
    color: #6c757d;
}

.sealab-finances-control {
    margin-bottom: 1rem;
}

.sealab-finances-card {
    height: 100%;
    border: none;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    transition: transform 0.2s ease-in-out;
}

.sealab-finances-card:hover {
    transform: translateY(-3px);
}

.sealab-finances-card-icon {
    font-size: 1.75rem;
    color: #0d6efd;
    margin-bottom: 0.75rem;
}

.sealab-finances-card-title {
    color: #6c757d;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.sealab-finances-card-value {
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 0;
}

.sealab-finances-card-text {
    font-size: 0.9rem;
    margin: 0.25rem 0;
    font-weight: bold;
}

.sealab-finances-table-card {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border: none;
}

.sealab-finances-table-header {
    background-color: #fff;
    border-bottom: 1px solid #e9ecef;
}

.sealab-finances-table th {
    font-weight: 600;
    color: #495057;
}

.sealab-finances-status {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.sealab-finances-status.completed {
    background-color: #d1e7dd;
    color: #0f5132;
}

.sealab-finances-status.pending {
    background-color: #fff3cd;
    color: #856404;
}

.sealab-finances-action-btn {
    white-space: nowrap;
}

.sealab-finances-search:focus,
.sealab-finances-select:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-color: #86b7fe;
}

/* Edit Panel Styles */
.vpo-edit-panel {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    background: #f9fafb;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.test3 {
    margin-bottom: 15px;
}

.vpo-edit-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.vpo-edit-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.vpo-edit-group label {
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #374151;
    letter-spacing: 0.05em;
}

.vpo-edit-group.full-width {
    grid-column: 1 / -1;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.save-btn-group {
    display: flex;
    justify-content: center;
}

/* Input Field Style */
.vpo-input {
    padding: 0.5rem 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #111827;
    background: #fff;
}

.vpo-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 1px #2563eb;
}

/* Notes Field */
.vpo-notes-group {
    grid-column: 1 / -1;
    margin-top: 1rem;
}

.vpo-notes-field {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #111827;
    resize: vertical;
    min-height: 100px;
    font-family: inherit;
    background: white;
}

.vpo-notes-field:focus {
    outline: none;
    border-color: #808080;
    box-shadow: 0 0 0 1px #808080;
}

.vpo-notes-field::placeholder {
    color: #9ca3af;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .sealab-finances {
        padding: 1.5rem;
    }

    .vpo-edit-panel {
        grid-template-columns: 1fr;
    }

    .save-btn-group {
        margin-top: 1rem;
    }
}

@media (max-width: 768px) {
    .sealab-finances {
        padding: 1rem;
    }

    .vpo-edit-panel {
        grid-template-columns: 1fr;
    }

    .save-btn-group {
        margin-top: 1rem;
    }

    .sealab-finances-table {
        grid-template-columns: 1fr;
    }
}

/* Print Styles */
@media print {
    .sealab-finances {
        padding: 0;
    }

    .vpo-edit-panel {
        display: none;
    }

    .vpo-card {
        break-inside: avoid;
    }
}
