.global-settings-page {
    background-color: #f8f9fa;
    /*min-height: 100vh;*/
    padding: 2rem 0;
}

.global-settings__container {
    max-width: 800px;
    margin: 0 auto;

    padding: 0 1rem;
}

.global-settings__title {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 700;
}

.global-settings__content {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.global-settings__section {
    margin-bottom: 2rem;
}

.global-settings__section-title {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e9ecef;
}

.global-settings__form-group {
    margin-bottom: 1.5rem;
}

.global-settings__label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #495057;
}

.global-settings__select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    color: #495057;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.global-settings__select:focus {
    border-color: #80bdff;
    outline: 0;
    /*box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);*/
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 1)
}

.global-settings__info-icon {
    margin-left: 0.5rem;
    color: #6c757d;
    cursor: pointer;
    transition: color 0.15s ease-in-out;
}

.global-settings__info-icon:hover {
    color: #333;
}

.global-settings__save-button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: black;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.global-settings__save-button:hover {
    background-color: #333;
}

.global-settings__save-button svg {
    margin-right: 0.5rem;
}

@media (max-width: 768px) {
    .global-settings__content {
        padding: 1.5rem;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.global-settings__content {
    animation: fadeIn 0.3s ease-in-out;
}