/* ProfileStyles.css */

.sealab-profile-wrapper {
    min-height: 100vh;
    background-color: #f8f8f8;
}

.sealab-profile-content {
    padding: 2rem 0;
}

.sealab-profile-card {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.sealab-profile-header {
    text-align: center;
    margin-bottom: 2rem;
}

.sealab-profile-title {
    color: #333;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.sealab-profile-avatar {
    width: 64px;
    height: 64px;
    background-color: #000;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    font-weight: 500;
    margin: 0 auto 1rem;
}

.sealab-profile-name {
    color: #333;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
}

.sealab-profile-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sealab-profile-button {
    display: flex;
    align-items: center;
    background-color: #000;
    color: white;
    text-decoration: none;
    padding: 1rem;
    border-radius: 12px;
    transition: all 0.2s ease;
}

.sealab-profile-button:hover {
    background-color: #333;
    color: white;
    transform: translateY(-2px);
}

.sealab-button-icon {
    width: 48px;
    height: 48px;
    background-color: #1a1a1a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    flex-shrink: 0;
    position: relative;
}

.sealab-button-icon i {
    font-size: 1.5rem;
    opacity: 0;
    position: absolute;
}

.emoji-icon {
    font-size: 1.25rem;
    position: absolute;
}

.sealab-button-content {
    flex: 1;
}

.sealab-button-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.sealab-button-subtitle {
    font-size: 0.875rem;
    opacity: 0.8;
}

@media (max-width: 768px) {
    .sealab-profile-card {
        padding: 1.5rem;
        margin: 0 1rem;
    }

    .sealab-profile-title {
        font-size: 1.75rem;
    }

    .sealab-button-icon {
        width: 40px;
        height: 40px;
    }

    .sealab-button-icon i {
        font-size: 1.25rem;
    }

    .emoji-icon {
        font-size: 1rem;
    }

    .sealab-button-title {
        font-size: 1rem;
    }

    .sealab-button-subtitle {
        font-size: 0.813rem;
    }
}