.ci-main {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
}

.ci-title {
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0;
    color: #000;
}

.ci-serial {
    color: #666;
    font-size: 1rem;
    margin-top: 0.5rem;
}

.ci-image-container {
    width: 100%;
    position: relative;
}

/* Override ImageCarousel arrows */
.ci-image-container :global(.carousel-control-prev),
.ci-image-container :global(.carousel-control-next) {
    background: none;
    opacity: 1;
    width: auto;
}

.ci-info-section {
    margin-bottom: 2rem;
}

.ci-section-heading {
    font-size: 1.25rem;
    font-weight: 500;
    color: #000;
    margin: 0;
}

.ci-divider {
    height: 1px;
    background-color: #000;
    margin: 0.75rem 0;
}

.ci-description {
    color: #333;
    line-height: 1.5;
    margin: 0;
}

.ci-dimensions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.ci-dimension-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background-color: #f8f8f8;
}

.ci-dimension-row span {
    color: #666;
}

.ci-dimension-value {
    color: #000;
    font-weight: 500;
}

.ci-quantity-control {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
}

.ci-quantity-btn {
    background: #f8f8f8;
    border: none;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.ci-quantity-btn:hover:not(:disabled) {
    background-color: #eee;
}

.ci-quantity-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.ci-quantity-display {
    font-size: 1.125rem;
    font-weight: 500;
    min-width: 2rem;
    text-align: center;
}

.ci-add-cart-btn {
    width: 100%;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 1rem;
    font-size: 1.125rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.ci-add-cart-btn:hover {
    background-color: #333;
}

@media (max-width: 768px) {
    .ci-wrapper {
        padding: 1rem 0;
    }

    .ci-title {
        font-size: 2rem;
    }

    .ci-main {
        padding: 0 1rem;
    }
}