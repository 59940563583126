/* EditProfileStyles.css */

.sealab-edit-profile {
    background-color: #f8f8f8;
    min-height: calc(100vh - 60px);
}

.sealab-profile-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    padding: 2.5rem;
}

.sealab-profile-header {
    text-align: center;
    margin-bottom: 3rem;
}

.sealab-profile-title {
    color: #000;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.sealab-profile-subtitle {
    color: #6c757d;
    font-size: 1.1rem;
    margin-bottom: 0;
}

.sealab-form-section {
    margin-bottom: 3rem;
}

.sealab-section-header {
    margin-bottom: 2rem;
}

.sealab-section-header h2 {
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.sealab-section-divider {
    height: 2px;
    background-color: #f0f0f0;
    margin-top: 0.5rem;
}

.sealab-form-group {
    margin-bottom: 0.5rem;
}

.sealab-form-group label {
    color: #000;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.sealab-input {
    border: 1.5px solid #e0e0e0;
    border-radius: 8px;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
}

.sealab-input:focus {
    border-color: #000;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.sealab-form-actions {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.sealab-submit-btn {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 1rem 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    min-width: 200px;
}

.sealab-submit-btn:hover {
    background-color: #333;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.sealab-submit-btn:active {
    transform: translateY(0);
}

.sealab-alert {
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 1.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;
    width: 90%;
    z-index: 1050;
    animation: slideDown 0.3s ease-out forwards;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sealab-alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.sealab-alert-error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.sealab-alert-message {
    margin: 0;
    font-size: 0.95rem;
    font-weight: 500;
    flex: 1;
    padding-right: 1rem;
}

.sealab-alert-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    color: inherit;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.sealab-alert-close:hover {
    opacity: 1;
}

@keyframes slideDown {
    from {
        transform: translate(-50%, -100%);
        opacity: 0;
    }
    to {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .sealab-profile-card {
        padding: 1.5rem;
        margin: 1rem;
    }

    .sealab-profile-title {
        font-size: 2rem;
    }

    .sealab-profile-subtitle {
        font-size: 1rem;
    }

    .sealab-section-header h2 {
        font-size: 1.25rem;
    }

    .sealab-form-section {
        margin-bottom: 2rem;
    }

    .sealab-submit-btn {
        width: 100%;
        padding: 0.875rem 1.5rem;
    }

    .sealab-alert {
        width: calc(100% - 2rem);
        margin: 0 1rem;
    }
}