.user-info {
    margin-top: 40px;
}

.checkout-container {
    padding: 20px;
    max-width: 800px;
    margin-top: 30px;
}

.checkout-items {
    list-style: none;
    padding: 0;
}

.checkout-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.checkout-item-image {
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
    margin-right: 20px;
}

.checkout-item-details {
    flex-grow: 1;
    padding-left: 20px;
}

.checkout-item-name {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.checkout-item-text {
    font-size: 1em;
}

.checkout-item-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.checkout-action-icon {
    cursor: pointer;
    margin: 0 10px;
    color: #000;
    font-size: 1.2em;
}

.checkout-quantity-control {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.quantity-icon-checkout {
    cursor: pointer;
    margin: 0 5px;
    color: #000;
}

.quantity-text-checkout {
    margin-right: 10px;
}

.empty-checkout-message {
    text-align: center;
    padding: 20px;
}

.submit-order-button-container {
    text-align: center;
    margin-top: 20px;
}

.submit-order-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 30px;
    margin-bottom: 13px;
}

.submit-order-button:hover {
    background-color: #333333;
}

.shipping-edit-form {
    display: flex;
    flex-direction: column;
}

.edit-shipping-text {
    padding: 1px;
    width: 350px;
}

.edit-shipping-label {
    font-weight: bold;
}

#edit-shipping-anchor {
    color: black;
}

#address-dropdown {
    display: flex;
    flex-direction: column;
}

#addressDropdown {
    width: 50%;
}

.addressDropdown {
    margin-top: 10px;
}

.order-info-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.order-info-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.order-info-input {
    padding: 1px;
    width: 350px;
    margin-bottom: 10px;
}

.project-address-link {
    color: black;
    margin-bottom: 10px;
}

.update-order-button {
    margin-top: 10px;
    width: 30%;
}

.radio-group {
    margin-bottom: 10px;
}

.radio-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.radio-options {
    display: flex;
    gap: 10px;
}

.radio-options label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.radio-options input[type="radio"] {
    margin-right: 5px;
}





