.showroom-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    /*background-color: #f8f9fa;*/
}

.filter-panel {
    padding: 15px; /* Reduced padding */
    background-color: #f8f9fa !important;
    color: black;
    border-bottom: 2px solid black;
    width: 100%;
    position: static;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 300px;
    max-width: 300px;
    /*height: 600px;*/
    height: fit-content;
    box-sizing: border-box;
}

.showroom-cstm-card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.showroom-btn-black {
    background-color: black !important;
    color: white !important;
}

.showroom-card-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px;
}

.showroom-card-title {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: bold;
}

.upload-sketch-text {
    text-align: center;
    margin-bottom: 1%;
    font-size: 1.1em;
}

.showroom-quote-warning {
    text-align: center;
    margin-bottom: 1%;
    font-style: italic;
    /*font-size: 1.1em;*/
}

.showroom-card-description {
    font-size: 0.9rem;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}


.showroom-quantity-input {
    margin-bottom: 10px;
}

.showroom-button-group {
    display: flex;
    justify-content: space-between;
}

.btn-black {
    flex: 1;
    margin: 0 5px;
}

.btn-black {
    background-color: black;
    color: white;
    margin-right: 5px;
    margin-top: 6px;
}

.btn-black:hover {
    color: black;
    border: 1px solid black;
}

@media (min-width: 1200px) {
    .showroom-container {
        flex-direction: row;
    }

    .filter-panel {
        width: 400px;
        border-right: 2px solid black;
        border-bottom-right-radius: 10px;
        top: 80px;
        z-index: 10;
    }
}

/* For iPads: Stack filter panel on top, reduce spacing */
@media (min-width: 768px) and (max-width: 1024px) {
    .showroom-container {
        flex-direction: column; /* Stack filter panel on top */
        gap: 0; /* Remove any gap between stacked elements */
    }

    .filter-panel {
        width: 100%; /* Full-width filter panel */
        max-width: 100%;
        margin-bottom: 10px; /* Slight margin to separate from cards */
        border-right: none;
        border-bottom: 2px solid black;
    }

    .showroom-cstm-card {
        max-width: 85%; /* Slightly narrower cards */
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .category-division, .dropdown-subcontent {
        margin-left: 10px;
    }

    .filter-panel {
        height: 350px;
        max-width: 300px;
    }
}

.filter-category {
    margin-bottom: 20px;
    width: 100%;
}

.category-division, .dropdown-subcontent {
    margin-left: 15px;
}

.sr-dropdown-toggle::after {
    display: none;
}

.sr-dropdown-content, .sr-dropdown-subcontent {
    display: block;
    background-color: black;
    color: white;
    padding: 10px 0;
}

.sr-dropdown-item {
    padding: 5px 20px;
    cursor: pointer;
    transition: background-color 0.5s;
    white-space: normal;
    word-wrap: break-word;
}

.sr-dropdown-item.selected {
    background-color: #555;
}

.showroom-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.sr-cstm-card {
    height: auto;
}

.card-img-top {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.sr-cstm-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sr-pagination .sr-pi .sr-pl {
    margin-top: 35px;
    color: white;
    background-color: black;
    border: none;
    box-shadow: none;
}

.sr-pagination .sr-pi.active .sr-pl {
    background-color: #555;
    border: none;
}

.custom-navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar-collapse {
    z-index: 1001;
}

@media (max-width: 992px) {
    .custom-navbar .navbar-collapse {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        background-color: #000;
        padding: 10px;
    }

    .showroom-container {
        /*margin-top: 80px;*/
    }

    .filter-panel {
        position: static;
    }
}

/* New styles for search functionality */
.showroom-search-container {
    margin-bottom: 20px;
}

.showroom-search-input {
    width: 98.5% !important;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.showroom-search-input:focus {
    outline: none;
    border-color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
