.order-page {
    background-color: #f8f9fa;
    min-height: 100vh;
}

.order-page__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;

}

.order-page__serial-number {
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.order-page__layout {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.order-page__image-section {
    flex: 1;
    min-width: 300px;
}

.order-page__image-wrapper {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    overflow: hidden;
}

.order-page__description {
    margin-top: 1rem;
    font-style: italic;
}

.order-page__form-section {
    flex: 2.5;
    min-width: 300px;
    margin-top: -20px;

}

.order-form {
    /*background-color: #ffffff;*/
    /*border-radius: 8px;*/
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
    padding: 2rem;
    background-color: #f8f9fa;
}

/* Section Styles */
.order-form__section {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    /*background-color: #f4f4f4;*/
    background-color: white;

}

.order-form__section-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

/* Group Styles */
.order-form__group {
    margin-bottom: 1.5rem;
}

.order-form__label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.order-form__input,
.order-form__select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
}

.order-form__input:focus,
.order-form__select:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.order-form__input--invalid {
    border-color: #dc3545;
}

.order-form__error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.order-form__tooltip-trigger {
    position: relative;
    display: inline-block;
    margin-left: 0.25rem;

    cursor: help;
}

.order-form__tooltip-trigger::after {
    content: "?";
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    background-color: #6c757d;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
}

.order-form__tooltip-content {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.order-form__tooltip-trigger:hover .order-form__tooltip-content,
.order-form__tooltip-trigger:focus .order-form__tooltip-content {
    visibility: visible;
    opacity: 1;
}

.order-form__checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.order-form__checkbox {
    margin-right: 0.5rem;
}

.order-form__checkbox-label {
    font-weight: normal;
}

.order-form__gap-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}

.order-form__gap-input-row {
    display: flex;
    justify-content: center;
    gap: 5rem;
    /*margin: 0.5rem 0;*/

}

.order-form__gap-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-form__gap-input {
    width: 80px;
}

.order-form__edgebanding-options {
    margin-top: 1.5rem;
}

.order-form__label--bold {
    font-weight: 700;
}

.order-form__edgebanding-square-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.order-form__edgebanding-square {
    width: 200px;
    height: 200px;
    background-color: #f8f9fa;
    border: 2px solid #ced4da;
    position: relative;
}

.order-form__edgebanding-edge {
    position: absolute;
    background-color: #ced4da;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.order-form__edgebanding-edge--top,
.order-form__edgebanding-edge--bottom {
    width: 100%;
    height: 20px;
}

.order-form__edgebanding-edge--right,
.order-form__edgebanding-edge--left {
    width: 20px;
    height: 100%;
}

.order-form__edgebanding-edge--top {
    top: 0;
}

.order-form__edgebanding-edge--right {
    right: 0;
}

.order-form__edgebanding-edge--bottom {
    bottom: 0;
}

.order-form__edgebanding-edge--left {
    left: 0;
}

.order-form__edgebanding-edge--selected {
    background-color: #007bff;
    color: #ffffff;
}

.order-form__edgebanding-label {
    font-size: 12px;
    pointer-events: none;
}

.order-form__edgebanding-label--vertical {
    transform: rotate(-90deg);
}

.order-form__submit-container {
    margin-top: 2rem;
    text-align: right;
}

.order-form__submit-button {
    background-color: #343a40;
    border: none;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-form__submit-button:hover {
    background-color: #23272b;
}

.order-form__depth-disclaimer {
    font-size: 0.8rem;
    color: red;
    font-style: italic;
}

.item-name-caution-text {
    font-size: 0.8rem;
    font-style: italic;
    color: red;
}

/* Responsive design */
@media (max-width: 768px) {
    .order-page__layout {
        flex-direction: column;
    }

    .order-page__image-section,
    .order-page__form-section {
        width: 100%;
    }

    .order-form__gap-input-row {
        /*flex-wrap: wrap;*/
    }

    .order-form__gap-input-wrapper {
        width: 100%;
        margin-bottom: 1rem;
    }

    .order-form__edgebanding-square {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 480px) {
    .order-page__container {
        padding: 1rem 0.5rem;
    }

    .order-form {
        padding: 1rem;
    }

    .order-form__submit-button {
        width: 100%;
    }
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.order-page__container {
    animation: fadeIn 0.5s ease-in-out;
}

.order-form__input:focus,
.order-form__select:focus {
    transition: border-color 0.3s, box-shadow 0.3s;
}

.order-form__edgebanding-edge {
    transition: background-color 0.3s, color 0.3s;
}

/* Accessibility improvements */
.order-form__input:focus,
.order-form__select:focus,
.order-form__checkbox:focus + .order-form__checkbox-label,
.order-form__submit-button:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
}

/* Print styles */
@media print {
    .order-page {
        background-color: #ffffff;
    }

    .order-page__container {
        max-width: 100%;
        padding: 0;
    }

    .order-form {
        box-shadow: none;
    }

    .order-form__submit-container {
        display: none;
    }
}
