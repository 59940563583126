.sealab-tutorial {
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff;
    overflow-x: hidden;
}

.sealab-tutorial__container {
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.sealab-tutorial__sidebar {
    width: 280px;
    min-width: 280px;
    background: #ffffff;
    border-right: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
    border-bottom-right-radius: 8px;
    /* Remove the general transition */
}

/* Add transition only when scrolling down */
.sealab-tutorial__sidebar.scrolled {
    top: 0;
    /*transition: top 0.3s ease;*/
}


.sealab-tutorial__content {
    flex: 1;
    margin-left: 280px;
    padding: 2rem;
    background-color: #ffffff;

    min-height: calc(100vh - 76px);
}

.sealab-tutorial__nav {
    padding: 2rem 0;
}

.sealab-tutorial__nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sealab-tutorial__nav-item {
    margin: 0;
    padding: 0;


}

.sealab-tutorial__nav-link {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    color: #495057;
    text-decoration: none;
    transition: all 0.2s ease;
    border-left: 3px solid transparent;

}

.sealab-tutorial__nav-link:hover {
    background-color: #f8f9fa;
    color: #000;
    border-left-color: #495057;
}

.sealab-tutorial__nav-number {
    font-size: 0.875rem;
    font-weight: 600;
    color: #6c757d;
    width: 32px;
    margin-right: 0.75rem;
}

.sealab-tutorial__nav-text {
    font-size: 0.9375rem;
    font-weight: 500;
}

.sealab-tutorial__content {
    flex: 1;
    margin-left: 280px; /* Match sidebar width */
    padding: 2rem;
    background-color: #ffffff;
    min-height: calc(100vh - 76px);
}

.sealab-tutorial__sections {
    max-width: 1200px;
    margin: 0 auto;
}

/* Video Grid Styles */
.sealab-tutorial__video-grid {
    display: grid;
    /*grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
    grid-template-columns: repeat(3, 1fr); /* Exactly 3 columns */
    gap: 2rem;
    margin-bottom: 3rem;
}

.sealab-tutorial__video-item {
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/*.sealab-tutorial__video-grid {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(3, 1fr); !* Exactly 3 columns *!*/
/*    gap: 2rem;*/
/*    margin-bottom: 3rem;*/
/*    max-width: 1200px;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*}*/

/*.sealab-tutorial__video-item {*/
/*    background: #ffffff;*/
/*    border-radius: 8px;*/
/*    overflow: hidden;*/
/*    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);*/
/*    transition: transform 0.2s ease, box-shadow 0.2s ease;*/
/*    width: 100%;*/
/*}*/


.sealab-tutorial__video-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.sealab-tutorial__video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    background-color: #ffffff;
    border-bottom: 2px solid black;
}

.sealab-tutorial__video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.sealab-tutorial__video-title {
    padding: 1rem;
    font-size: 0.9375rem;
    font-weight: 600;
    /*color: #212529;*/
    color: white;
    text-align: center;
    margin: 0;
    /*background-color: #ffffff;*/
    background-color: black;
}

/* Section Styles */
.sealab-tutorial__section {
    margin-bottom: 4rem;
    padding-top: 1rem;
    background-color: #ffffff;
}

.sealab-tutorial__section-title {
    font-size: 2rem;
    font-weight: 700;
    color: #212529;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #e9ecef;
}

/* Responsive Design */
@media (max-width: 991.98px) {
    .sealab-tutorial__sidebar {
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
        border-right: none;
        border-bottom: 1px solid #e9ecef;
    }

    .sealab-tutorial__video-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
        gap: 1.5rem;
    }

    .sealab-tutorial__container {
        flex-direction: column;
        padding-top: 0;
    }

    .sealab-tutorial__content {
        margin-left: 0;
        max-width: 100%;
        padding: 1.5rem;
    }

    .sealab-tutorial__nav {
        padding: 1rem 0;
    }
}

@media (max-width: 767.98px) {
    .sealab-tutorial__video-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .sealab-tutorial__section-title {
        font-size: 1.5rem;
    }
}