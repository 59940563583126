.custom-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-alert-content {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-alert-content p {
    margin-bottom: 20px;
}

.custom-alert-content button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.custom-alert-content button:hover {
    background-color: #333;
}

.cart-duplicate-link {
    color: black;
    text-decoration: none;
}

.cart-duplicate-link:hover {
    color: black;
    text-decoration: underline;
}

.cart-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.cart-full-page {
    /* background-color remains unchanged */
}

.cart-items {
    list-style: none;
    padding: 0;
}

.cart-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
}

.cart-item-text {
    font-size: 1em;
    margin-top: 15px;
}

.cart-item-image {
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
    margin-right: 20px !important;
}

.cart-item-details {
    flex-grow: 1;
    padding-left: 20px;
}

.cart-item-name {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.cart-item-actions {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.configure-cabinets-container {
    text-align: center;
}

.icon-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.action-icon {
    cursor: pointer;
    margin: 0 10px;
    color: #000;
    font-size: 1.2em;
}

.quantity-control {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.quantity-icon {
    cursor: pointer;
    margin-top: 10px;
    margin-left: 5px;
    color: #000;
}

.quantity-text {
    margin-top: 15px;
    margin-right: 10px;
    font-size: 1em;
}

.empty-cart-message {
    text-align: center;
    padding: 20px;
}

.submit-order-button-container {
    text-align: center;
    margin-top: 20px;
}

.save-order-button-container {
    text-align: center;
    margin-top: 5px;
}

.save-order-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 207px;
}

.submit-order-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: 207px;
}

.submit-order-button:hover {
    background-color: #333333;
}

.save-order-button:hover {
    background-color: #333333;
}

.clear-cart-link {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.dimension-field {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}

.dimension-error {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}

.editable-dimension {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1px;
    width: 50px;
}

.field-label {
    margin-right: 10px;
    font-weight: bold;
}

.search-container {
    width: 100%;
    margin-bottom: 20px;
}

.cart-search-input {
    width: 100%;
    padding: 8px 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    position: relative;
}

.cart-search-input:focus {
    border-color: #555;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.search-container {
    position: relative;
}

.search-suggestions {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 10;
}

.search-suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.search-suggestion-item:hover {
    background-color: #f0f0f0;
}

.search-suggestions li:last-child {
    border-bottom: none;
}

.manage-settings-link {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.manage-settings-link:hover {
    text-decoration: underline;
}

.cart-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    padding: 10px;
    /*background-color: #f8f9fa;*/
    border-radius: 5px;
}

.cart-toolbar-btn {
    flex: 1;
    min-width: 150px;
    padding: 8px 16px;
    /*background-color: #343a40;*/
    /*background-color: #23272b;*/
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    text-align: center;
    max-height: 50px;
    font-size: 0.8rem;
}

.cart-toolbar-btn:hover {
    /*background-color: #23272b;*/
    background-color: #343a40;
}

.global-settings-applied-text {
    margin-top: 3px;
}

.special-instructions-container {
    width: 100%;
    margin: 20px 0;
    padding: 0 10px;
}

.special-instructions-textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    resize: vertical;
    min-height: 100px;
    font-family: inherit;
    transition: border-color 0.3s ease;
}

.special-instructions-textarea:focus {
    outline: none;
    border-color: #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.special-instructions-textarea::placeholder {
    color: #999;
}

@media screen and (max-width: 767px) {
    .cart-toolbar {
        flex-direction: column;
    }

    .cart-toolbar-btn {
        width: 100%;
    }

    .cart-search-input {
        padding: 8px;
        font-size: 13px;
    }

    .search-suggestions {
        max-height: 120px;
    }

    .search-suggestion-item {
        padding: 6px 8px;
    }

    .cart-container {
        padding: 10px;
    }

    .cart-item {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 10px;
        align-items: start;
        padding: 15px 0;
    }

    .cart-item-image {
        grid-column: 1;
        width: 100px;
        height: auto;
    }

    .cart-item-details {
        grid-column: 2 / span 2;
        padding-left: 0;
    }

    .cart-item-text {
        font-size: 0.98em;
    }

    .quantity-control {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .quantity-text {
        margin-right: 10px;
    }

    .quantity-icon {
        /*margin: 0 5px;*/
        font-size: 1.2em;
    }

    .cart-item-dimensions {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .dimension-field {
        margin-right: 0;
    }

    .cart-item-actions {
        grid-column: 3;
        grid-row: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .icon-actions {
        margin-top: 10px;
    }

    .submit-order-button,
    .save-order-button {
        width: 100%;
    }

    .custom-alert-content {
        width: 90%;
        max-width: 300px;
    }

    .global-settings-applied-text {
        margin-right: 20px;
        margin-top: 4px;
    }

    .special-instructions-container {
        padding: 0 5px;
        margin: 15px 0;
    }

    .special-instructions-textarea {
        padding: 10px;
        font-size: 13px;
    }
}
