/*!* Container *!*/
/*.om-container {*/
/*    padding: 2rem;*/
/*    background-color: #fafafa;*/
/*    min-height: 100vh;*/
/*}*/

/*!* Header row (filters & search) *!*/
/*.om-header-row {*/
/*    margin-bottom: 2rem;*/
/*}*/

/*.om-search-form {*/
/*    !*background-color: #fff;*!*/
/*    !*padding: 0.75rem;*!*/
/*    !*border-radius: 4px;*!*/
/*    !*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);*!*/
/*}*/

/*.om-search-wrapper {*/
/*    display: flex;*/
/*    gap: 1rem;*/
/*}*/

/*!* Inputs & Buttons *!*/
/*.om-input,*/
/*.om-filter-input,*/
/*.om-input-email-filter {*/
/*    border: 1px solid #dee2e6;*/
/*    padding: 0.5rem 1rem;*/
/*    border-radius: 4px;*/
/*    flex-grow: 1;*/
/*}*/

/*.om-input:focus,*/
/*.om-filter-input:focus,*/
/*.om-input-email-filter:focus {*/
/*    border-color: #000;*/
/*    box-shadow: none;*/
/*}*/

/*.om-filter-label {*/
/*    font-weight: 500;*/
/*    margin-bottom: 0.5rem;*/
/*    display: inline-block;*/
/*}*/

/*.om-search-btn,*/
/*.om-save-btn,*/
/*.om-create-material-btn,*/
/*.om-create-edgebanding-btn {*/
/*    background-color: #000;*/
/*    border: none;*/
/*    min-width: 100px;*/
/*    color: #fff;*/
/*}*/

/*.om-search-btn:hover,*/
/*.om-save-btn:hover,*/
/*.om-create-material-btn:hover,*/
/*.om-create-edgebanding-btn:hover {*/
/*    background-color: #333;*/
/*}*/

/*.om-alert {*/
/*    margin-bottom: 2rem;*/
/*}*/

/*!* Orders row *!*/
/*.om-orders-row {*/
/*    margin-top: 1rem;*/
/*}*/

/*!* Order Cards *!*/
/*.om-order-card {*/
/*    cursor: pointer;*/
/*    transition: box-shadow 0.2s ease;*/
/*    border: 1px solid #dee2e6;*/
/*}*/

/*.om-order-card:hover {*/
/*    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);*/
/*}*/

/*.om-order-card-content p {*/
/*    margin: 0;*/
/*    padding: 0.25rem 0;*/
/*}*/

/*!* Single Order Content *!*/
/*.om-order-content {*/
/*    background-color: #fff;*/
/*    border-radius: 4px;*/
/*    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);*/
/*    padding: 2rem;*/
/*}*/

/*!* Cards *!*/
/*.om-order-details,*/
/*.om-special-instructions {*/
/*    height: 100%;*/
/*    border: 1px solid #dee2e6;*/
/*    border-radius: 4px;*/
/*}*/

/*.om-card-header {*/
/*    background-color: #f8f9fa;*/
/*    border-bottom: 1px solid #dee2e6;*/
/*    padding: 1rem;*/
/*}*/

/*.om-card-title {*/
/*    margin: 0;*/
/*    font-size: 1.1rem;*/
/*    font-weight: 600;*/
/*    color: #000;*/
/*}*/

/*.om-card-body {*/
/*    padding: 1.5rem;*/
/*}*/

/*!* Details Table *!*/
/*.om-details-table {*/
/*    margin-bottom: 0;*/
/*    width: 100%;*/
/*}*/

/*.om-details-table td {*/
/*    padding: 0.75rem 0;*/
/*    border-bottom: 1px solid #f0f0f0;*/
/*}*/

/*.om-details-table tr:last-child td {*/
/*    border-bottom: none;*/
/*}*/

/*.om-details-label {*/
/*    color: #666;*/
/*    font-weight: 500;*/
/*    width: 140px;*/
/*    padding-right: 1rem;*/
/*    vertical-align: top;*/
/*}*/

/*.om-details-value {*/
/*    color: #000;*/
/*}*/

/*.om-instructions-text {*/
/*    margin: 0;*/
/*    white-space: pre-wrap;*/
/*    color: #000;*/
/*}*/

/*!* Tables for materials/edgebanding *!*/
/*.om-table-wrapper {*/
/*    margin-top: 1rem;*/
/*    overflow-x: auto;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.om-table {*/
/*    margin-bottom: 0;*/
/*    width: 100%;*/
/*}*/

/*.om-table thead th {*/
/*    background-color: #f8f9fa;*/
/*    border-bottom: 2px solid #dee2e6;*/
/*    font-weight: 600;*/
/*    padding: 1rem;*/
/*    white-space: nowrap;*/
/*}*/

/*.om-article-row td {*/
/*    vertical-align: middle;*/
/*    padding: 1rem;*/
/*}*/

/*.om-preview-cell {*/
/*    width: 100px;*/
/*}*/

/*.om-preview-image {*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    object-fit: cover;*/
/*    border-radius: 4px;*/
/*}*/

/*.om-no-image {*/
/*    width: 80px;*/
/*    height: 80px;*/
/*    background-color: #f8f9fa;*/
/*    border: 1px solid #dee2e6;*/
/*    border-radius: 4px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: 0.8rem;*/
/*    color: #6c757d;*/
/*    text-align: center;*/
/*}*/

/*!* Material & Edgebanding Selects *!*/
/*.om-material-select {*/
/*    min-width: 200px;*/
/*}*/

/*.om-table-card {*/
/*    border: none;*/
/*    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);*/
/*}*/

/*!* Nav Tabs *!*/
/*.om-nav-tabs {*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    border-bottom: none;*/
/*    margin-bottom: -1px;*/
/*}*/

/*.om-nav-link {*/
/*    color: #6c757d;*/
/*    border: none;*/
/*    padding: 0.75rem 1.5rem;*/
/*    font-weight: 500;*/
/*    transition: color 0.2s ease;*/
/*    cursor: pointer;*/
/*}*/

/*.om-nav-link:hover {*/
/*    color: #000;*/
/*}*/

/*.om-nav-link.active {*/
/*    color: #000;*/
/*    background-color: transparent;*/
/*    border-bottom: 2px solid #000;*/
/*}*/

/*!* Checkboxes *!*/
/*.om-checkbox {*/
/*    margin: 0;*/
/*    cursor: pointer;*/
/*}*/

/*!* Status Card *!*/
/*.om-status-card {*/
/*    border: 1px solid #dee2e6;*/
/*    border-radius: 4px;*/
/*}*/

/*!* Modals *!*/
/*.om-modal-new-material .modal-content,*/
/*.om-modal-new-edgebanding .modal-content {*/
/*    border-radius: 4px;*/
/*}*/

/*!* Responsive Styles *!*/
/*@media (max-width: 768px) {*/
/*    .om-container {*/
/*        padding: 1rem;*/
/*    }*/

/*    .om-search-wrapper {*/
/*        flex-direction: column;*/
/*    }*/

/*    .om-search-btn,*/
/*    .om-save-btn,*/
/*    .om-create-material-btn,*/
/*    .om-create-edgebanding-btn {*/
/*        width: 100%;*/
/*    }*/

/*    .om-order-content {*/
/*        padding: 1rem;*/
/*    }*/

/*    .om-details-table td {*/
/*        display: block;*/
/*        padding: 0.375rem 0;*/
/*    }*/

/*    .om-details-label {*/
/*        width: 100%;*/
/*        border-bottom: none !important;*/
/*    }*/

/*    .om-details-value {*/
/*        padding-left: 1rem;*/
/*    }*/

/*    .om-card-body {*/
/*        padding: 1rem;*/
/*    }*/

/*    .om-table {*/
/*        font-size: 0.9rem;*/
/*    }*/

/*    .om-material-select {*/
/*        min-width: 150px;*/
/*    }*/
/*}*/

/*@media (max-width: 576px) {*/
/*    .om-material-select {*/
/*        min-width: 120px;*/
/*    }*/

/*    .om-preview-image,*/
/*    .om-no-image {*/
/*        width: 60px;*/
/*        height: 60px;*/
/*    }*/

/*    .om-nav-link {*/
/*        padding: 0.5rem 1rem;*/
/*    }*/

/*    .om-card-header {*/
/*        padding: 0.75rem;*/
/*    }*/

/*    .om-card-body {*/
/*        padding: 0.75rem;*/
/*    }*/
/*}*/


/*.order-status-wrapper {*/
/*    position: absolute;*/
/*    top: 10px;*/
/*    right: 10px;*/
/*}*/

/*.order-status {*/
/*    padding: 0.35rem 0.75rem;*/
/*    border-radius: 20px;*/
/*    font-size: 0.875rem;*/
/*    font-weight: 500;*/
/*}*/

/*.status-pending {*/
/*    background-color: #FEF3C7;*/
/*    color: #92400E;*/
/*}*/

/*.status-rfp {*/
/*    background-color: #DCFCE7;*/
/*    color: #15803D;*/
/*}*/

/*.status-in-production {*/
/*    background-color: #E0F2FE;*/
/*    color: #075985;*/
/*}*/

/*.status-delivered {*/
/*    background-color: #E0E7FF;*/
/*    color: #3730A3;*/
/*}*/

/*.status-shipped {*/
/*    background-color: #F3E8FF;*/
/*    color: #6B21A8;*/
/*}*/

/*.status-complete {*/
/*    background-color: #DCFCE7;*/
/*    color: #15803D;*/
/*}*/

/*.status-default {*/
/*    background-color: #edf2f7;*/
/*    color: #4a5568;*/
/*}*/


/* Container */
.om-container {
    padding: 2rem;
    background-color: #fafafa;
    min-height: 100vh;
}

/* Header row (filters & search) */
.om-header-row {
    margin-bottom: 2rem;
}

.om-search-form {
    /*background-color: #fff;*/
    /*padding: 0.75rem;*/
    /*border-radius: 4px;*/
    /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);*/
}

.om-search-wrapper {
    display: flex;
    gap: 1rem;
}

/* Inputs & Buttons */
.om-input,
.om-filter-input,
.om-input-email-filter {
    border: 1px solid #dee2e6;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    flex-grow: 1;
}

.om-input:focus,
.om-filter-input:focus,
.om-input-email-filter:focus {
    border-color: #000;
    box-shadow: none;
}

.om-filter-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
    display: inline-block;
}

.om-search-btn,
.om-save-btn,
.om-create-material-btn,
.om-create-edgebanding-btn {
    background-color: #000;
    border: none;
    min-width: 100px;
    color: #fff;
}

.om-search-btn:hover,
.om-save-btn:hover,
.om-create-material-btn:hover,
.om-create-edgebanding-btn:hover {
    background-color: #333;
}

.om-alert {
    margin-bottom: 2rem;
}

/* Orders row */
.om-orders-row {
    margin-top: 1rem;
}

/* Order Cards */
.om-order-card {
    cursor: pointer;
    transition: box-shadow 0.2s ease;
    border: 1px solid #dee2e6;
}

.om-order-card:hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.om-order-card-content p {
    margin: 0;
    padding: 0.25rem 0;
}

/* Single Order Content */
.om-order-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

/* Cards */
.om-order-details,
.om-special-instructions {
    height: 100%;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.om-card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem;
}

.om-card-title {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
}

.om-card-body {
    padding: 1.5rem;
}

/* Details Table */
.om-details-table {
    margin-bottom: 0;
    width: 100%;
}

.om-details-table td {
    padding: 0.75rem 0;
    border-bottom: 1px solid #f0f0f0;
}

.om-details-table tr:last-child td {
    border-bottom: none;
}

.om-details-label {
    color: #666;
    font-weight: 500;
    width: 140px;
    padding-right: 1rem;
    vertical-align: top;
}

.om-details-value {
    color: #000;

}

.discount-inputs {
    width: 30%
}

.om-instructions-text {
    margin: 0;
    white-space: pre-wrap;
    color: #000;
}

/* Tables for materials/edgebanding */
.om-table-wrapper {
    margin-top: 1rem;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.om-table {
    margin-bottom: 0;
    width: 100%;
}

.om-table thead th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    font-weight: 600;
    padding: 1rem;
    white-space: nowrap;
}

.om-article-row td {
    vertical-align: middle;
    padding: 1rem;
}

.om-preview-cell {
    width: 100px;
}

.om-preview-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.om-no-image {
    width: 80px;
    height: 80px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: #6c757d;
    text-align: center;
}

/* Material & Edgebanding Selects */
.om-material-select {
    min-width: 200px;
}

.om-table-card {
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Nav Tabs */
.om-nav-tabs {
    display: flex;
    justify-content: flex-start;
    border-bottom: none;
    margin-bottom: -1px;
}

.om-nav-link {
    color: #6c757d;
    border: none;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    transition: color 0.2s ease;
    cursor: pointer;
}

.om-nav-link:hover {
    color: #000;
}

.om-nav-link.active {
    color: #000;
    background-color: transparent;
    border-bottom: 2px solid #000;
}

/* Checkboxes */
.om-checkbox {
    margin: 0;
    cursor: pointer;
}

/* Status Card */
.om-status-card {
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

/* Modals */
.om-modal-new-material .modal-content,
.om-modal-new-edgebanding .modal-content {
    border-radius: 4px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .om-container {
        padding: 1rem;
    }

    .om-search-wrapper {
        flex-direction: column;
    }

    .om-search-btn,
    .om-save-btn,
    .om-create-material-btn,
    .om-create-edgebanding-btn {
        width: 100%;
    }

    .om-order-content {
        padding: 1rem;
    }

    .om-details-table td {
        display: block;
        padding: 0.375rem 0;
    }

    .om-details-label {
        width: 100%;
        border-bottom: none !important;
    }

    .om-details-value {
        padding-left: 1rem;
    }

    .om-card-body {
        padding: 1rem;
    }

    .om-table {
        font-size: 0.9rem;
    }

    .om-material-select {
        min-width: 150px;
    }
}

@media (max-width: 576px) {
    .om-material-select {
        min-width: 120px;
    }

    .om-preview-image,
    .om-no-image {
        width: 60px;
        height: 60px;
    }

    .om-nav-link {
        padding: 0.5rem 1rem;
    }

    .om-card-header {
        padding: 0.75rem;
    }

    .om-card-body {
        padding: 0.75rem;
    }
}


.order-status-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
}

.order-status {
    padding: 0.35rem 0.75rem;
    border-radius: 20px;
    font-size: 0.875rem;
    font-weight: 500;
}

.status-pending {
    background-color: #FEF3C7;
    color: #92400E;
}

.status-rfp {
    background-color: #DCFCE7;
    color: #15803D;
}

.status-in-production {
    background-color: #E0F2FE;
    color: #075985;
}

.status-delivered {
    background-color: #E0E7FF;
    color: #3730A3;
}

.status-shipped {
    background-color: #F3E8FF;
    color: #6B21A8;
}

.status-complete {
    background-color: #DCFCE7;
    color: #15803D;
}

.status-default {
    background-color: #edf2f7;
    color: #4a5568;
}
