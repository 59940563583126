.custom-navbar {
    background-color: #000; /* Black background */
    padding-left: 20px; /* Padding to the left of the logo */
    padding-right: 20px; /* Padding to the right of the navbar options */
    height: 80px; /* Increased navbar height */
}

.custom-navbar .navbar-brand {
    font-weight: bold;
    color: #fff; /* White text */
    padding-left: 20px; /* Additional padding to the left of the logo */
    font-size: 30px;
}

.custom-navbar .navbar-nav .nav-link {
    padding-right: 20px; /* Additional padding to the right of each nav item */
    color: #fff; /* White text */
}

.custom-navbar .nav-link:hover {

    color: #f8f9fa; /* Slightly lighter white on hover */
}

/* Content styling */
.content {
    padding: 20px;

    /* Add more styles for your content here */
}

#navbar-options {
    justify-content: end;
    float: right;
}

#responsive-navbar-nav {
    justify-content: end;
    padding-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .custom-navbar {
        height: auto; /* Reset height for mobile */
    }

    .custom-navbar .navbar-toggler {
        border-color: #fff; /* White border for the toggle button */
    }

    .custom-navbar .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#fff' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/></svg>");
        /* Custom white hamburger icon */
    }
}


.scrollable-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height as needed */
    padding: 20px;
}

.scrollable-section {
    display: flex;
    overflow-x: auto;
    gap: 20px;
}

.image-card {
    flex: 0 0 auto;
    width: 300px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    text-align: center; /* Center the text below the image */
}

.image-card img {
    width: 100%;
    height: auto;
    display: block;
}

.image-card, .add-to-cart-icon {
    user-select: none; /* Disables text selection */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Chrome/Safari */
    -ms-user-select: none; /* IE/Edge */
}

.card-text {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.nav-cart-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cart-quantity-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
}

/* ... (other styles) ... */

.add-to-cart-icon {

    padding: 12px; /* Increased padding for a larger icon */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.add-to-cart-icon:hover {
    transform: scale(2.1); /* Slightly enlarges the icon on hover */
}

.landing-page {
    text-align: center;
    padding-top: 50px; /* Adjust as needed */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@keyframes bounce-left-right {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}


.ordering-button-container {
    text-align: center;
    margin-top: 450px; /* Space from the navbar */

}

.ordering-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    /*animation: bounce-left-right 1s ease-in-out infinite;*/
}

.ordering-button:hover {
    background-color: white;
    color: black;
    border: 2px solid black;
    animation: none;
}

.under-construction-header {
    color: #333; /* Dark grey text color */
    font-size: 48px; /* Large font size for visibility */
    font-weight: bold; /* Bold text for emphasis */
    margin-top: 50px; /* Top margin for spacing from the navbar */
    text-align: center; /* Center alignment of the header */
}

.coming-soon-text {
    color: #666; /* Medium grey text color */
    font-size: 24px; /* Readable font size */
    margin-top: 20px; /* Top margin for spacing from the header */
    text-align: center; /* Center alignment of the text */
}

