.doc-image-carousel-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid red;*/
}

.doc-image-container {
    /*min-width: 300px; !* Limiting max width to 300px for larger screens *!*/
    width: 100%;
    height: auto;
    /*border: 1px solid red;*/

}

.doc__image-wrapper {
    background-color: #ffffff;
    border-radius: 8px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
    margin-bottom: 1rem;
    overflow: hidden;
    width: 500px;
}

.doc-image-carousel-controls {
    position: absolute;
    top: 50%;
    width: 102%; /* Make the controls fit the width of the image */
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-50%);
    pointer-events: none; /* Only arrows are clickable */

}

