.saved-orders-page {
    background-color: #fff;
    min-height: 100vh;
}

.container-wide {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.empty-state {
    text-align: center;
    padding: 60px 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.empty-state h5 {
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
}

.empty-state p {
    color: #666;
    font-size: 1rem;
}

.saved-order-container {
    margin-bottom: 32px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    transition: transform 0.2s ease;
}

.saved-order-container:hover {
    transform: translateY(-2px);
}

.saved-order-header {
    background-color: #000;
    padding: 20px;
}

.saved-order-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.saved-order-id {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.5px;
}

.pdf-button-wrapper {
    background: transparent;
    padding: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    transition: all 0.2s ease;
}

.pdf-button-wrapper:hover {
    border-color: rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.1);
}

.saved-order-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 24px;
    background-color: #fff;
}

.saved-order-item {
    display: flex;
    flex-direction: column;
}

.saved-order-card {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 24px;
    height: 100%;
    transition: box-shadow 0.2s ease;
}

.saved-order-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.saved-order-image-wrapper {
    background: #ffffff;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.saved-order-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    /*border: 1px solid #eee;*/
    border-radius: 6px;
    padding: 20px;
    min-height: 200px;
}

.saved-order-item-img {
    width: 100%;
    max-height: 180px;
    object-fit: contain;
    transition: transform 0.2s ease;
}

.saved-order-image:hover .order-item-img {
    transform: scale(1.05);
}

.saved-order-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
}

.detail-row:last-child {
    border-bottom: none;
}

.detail-label {
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
}

.detail-value {
    color: #000;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: right;
}

.saved-order-footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 24px;
    background-color: #fafafa;
    border-top: 1px solid #eee;
}

.btn-order-again,
.btn-delete {
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    transition: all 0.2s ease;
    min-width: 160px;
    cursor: pointer;
    border: 2px solid #000;
}

.btn-order-again {
    background-color: #000;
    color: #fff;
}

.btn-order-again:hover {
    background-color: #fff;
    color: #000;
}

.btn-delete {
    background-color: #fff;
    color: #000;
}

.btn-delete:hover {
    background-color: #000;
    color: #fff;
}

@media (max-width: 768px) {
    .container-wide {
        padding: 0 16px;
    }

    .saved-order-items {
        grid-template-columns: 1fr;
        padding: 16px;
        gap: 16px;
    }

    .order-header-content {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
    }

    .pdf-button-wrapper {
        width: 100%;
        text-align: center;
    }

    .saved-order-footer {
        flex-direction: column;
        gap: 12px;
    }

    .btn-order-again,
    .btn-delete {
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .saved-order-items {
        grid-template-columns: repeat(2, 1fr);
    }
}