/*!* Container & General Layout *!*/
/*.trc-container {*/
/*    padding: 40px;*/
/*    background-color: #fff;*/
/*}*/

/*.trc-header-row {*/
/*    margin-bottom: 40px;*/
/*}*/

/*.trc-title {*/
/*    font-size: 2.5rem;*/
/*    font-weight: bold;*/
/*    color: #000;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.trc-subtitle {*/
/*    font-size: 1.1rem;*/
/*    color: #555;*/
/*}*/

/*!* Loading & Error States *!*/
/*.trc-loading-row,*/
/*.trc-error-row {*/
/*    margin-top: 50px;*/
/*}*/

/*.trc-spinner {*/
/*    width: 3rem;*/
/*    height: 3rem;*/
/*}*/

/*.trc-loading-text {*/
/*    margin-top: 15px;*/
/*    font-size: 1.2rem;*/
/*    color: #333;*/
/*}*/

/*.trc-alert {*/
/*    font-size: 1.1rem;*/
/*}*/

/*!* Panels Grid *!*/
/*.trc-panels-row {*/
/*    margin-top: 30px;*/
/*}*/

/*!* Panel Layout *!*/
/*.trc-panel-col {*/
/*    margin-bottom: 30px;*/
/*}*/

/*.trc-panel {*/
/*    background-color: #f7f7f7;*/
/*    border: 1px solid #ddd;*/
/*    border-radius: 6px;*/
/*    overflow: hidden;*/
/*    transition: transform 0.3s ease, box-shadow 0.3s ease;*/
/*}*/

/*.trc-panel:hover {*/
/*    transform: translateY(-3px);*/
/*    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);*/
/*}*/

/*!* Panel Header *!*/
/*.trc-panel-header {*/
/*    background-color: #000;*/
/*    color: #fff;*/
/*    padding: 15px 20px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.trc-header-icon {*/
/*    font-size: 1.5rem;*/
/*    margin-right: 10px;*/
/*}*/

/*.trc-panel-title {*/
/*    font-size: 1.25rem;*/
/*    margin: 0;*/
/*}*/

/*!* Panel List *!*/
/*.trc-panel-list {*/
/*    list-style: none;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*.trc-panel-item {*/
/*    padding: 12px 20px;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    border-bottom: 1px solid #eee;*/
/*    font-size: 1rem;*/
/*    color: #333;*/
/*}*/

/*.trc-panel-item:last-child {*/
/*    border-bottom: none;*/
/*}*/

/*.trc-item-name {*/
/*    font-weight: 500;*/
/*}*/

/*.trc-item-count {*/
/*    font-weight: bold;*/
/*    color: #000;*/
/*}*/

/*.trc-no-data {*/
/*    text-align: center;*/
/*    font-style: italic;*/
/*    color: #777;*/
/*    padding: 15px;*/
/*}*/

/*!* Responsive Adjustments *!*/
/*@media (max-width: 992px) {*/
/*    .trc-panels-row {*/
/*        margin-top: 20px;*/
/*    }*/
/*}*/


.trc-container {
    background-color: #ffffff;
}

.trc-header-wrapper {
    display: flex;
    align-items: center;
}

.trc-icon-wrapper {
    display: flex;
    align-items: center;
    line-height: 1;
}

.trc-header-icon {
    font-size: 1.25rem;
    opacity: 0.9;
    display: flex;
    align-items: center;
    line-height: 1;
    margin-top: 15px;
}

.trc-panel {
    transition: transform 0.2s ease-in-out;
}

.trc-panel:hover {
    transform: translateY(-2px);
}

.trc-item-name {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
}

.trc-item-count {
    font-size: 0.85rem;
    min-width: 40px;
    text-align: center;
}

.trc-section-title {
    letter-spacing: 0.5px;
    color: #333;
}

.trc-panel-title {
    line-height: 1;
}