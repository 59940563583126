/* CustomerPortalStyles.css */
.portal-wrapper {
    min-height: 100vh;
    background-color: #f8f9fa;
}

.portal-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.portal-title {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    color: #2d3748;
    margin-bottom: 2.5rem;
}

/* Search styles */
.search-container {
    /*max-width: 800px;*/

    margin: 0 auto 2rem;
    /*padding: 0 1rem;*/
}

.search-input {
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    background-color: white;
    transition: all 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: #4a5568;
    box-shadow: 0 0 0 3px rgba(74, 85, 104, 0.1);
}

/* Order card styles */
.order-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    height: 100%;
}

.order-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.order-card-body {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.order-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
}

.order-number {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
}

.order-status {
    padding: 0.35rem 0.75rem;
    border-radius: 20px;
    font-size: 0.875rem;
    font-weight: 500;
}

.status-pending {
    background-color: #FEF3C7;
    color: #92400E;
}

.status-in-production {
    background-color: #E0F2FE;
    color: #075985;
}

.status-ready {
    background-color: #F0FDF4;
    color: #166534;
}

.status-shipped {
    background-color: #F3E8FF;
    color: #6B21A8;
}

.status-delivered {
    background-color: #E0E7FF;
    color: #3730A3;
}

.status-complete {
    background-color: #DCFCE7;
    color: #15803D;
}

.status-default {
    background-color: #edf2f7;
    color: #4a5568;
}

.order-details {
    flex-grow: 1;
    margin-bottom: 1.25rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

.detail-row:last-child {
    margin-bottom: 0;
}

.detail-label {
    color: #718096;
    font-size: 0.875rem;
    font-weight: 500;
}

.detail-value {
    color: #2d3748;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: right;
}

.order-card-actions {
    margin-top: auto;
    text-align: right;
}

.view-details-btn {
    padding: 0.5rem 1.25rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    background-color: #000000;
    border: 2px solid #000000;
    color: white;
    transition: all 0.2s ease;
}

.view-details-btn:hover {
    background-color: #1a1a1a;
    border-color: #1a1a1a;
    color: white;
}

.view-details-btn:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

/* Loading state */
.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

/* Empty and No Results states */
.empty-state,
.no-results {
    text-align: center;
    padding: 4rem 1rem;
    color: #4a5568;
}

.empty-state h2,
.no-results h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.empty-state p,
.no-results p {
    font-size: 1.125rem;
    color: #718096;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .portal-title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .search-input {
        padding: 0.75rem 1rem;
    }

    .order-card-body {
        padding: 1.25rem;
    }

    .order-number {
        font-size: 1.125rem;
    }
}