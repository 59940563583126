/*!* Base Styles *!*/
/*body {*/
/*    font-family: 'Arial', sans-serif;*/
/*    background-color: #fff;*/
/*    color: #333;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*!* Order Summary Page *!*/
/*.os-page {*/
/*    background-color: #fff;*/
/*    min-height: 100vh;*/
/*    padding: 40px 0;*/
/*}*/

/*.os-container {*/
/*    max-width: 1400px;*/
/*    margin: 0 auto;*/
/*    padding: 0 20px;*/
/*}*/

/*.os-main {*/
/*    background-color: #fff;*/
/*    border: 1px solid #e0e0e0;*/
/*    border-radius: 8px;*/
/*    padding: 20px;*/
/*}*/

/*!* Header *!*/
/*.os-header {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    border-bottom: 2px solid #000;*/
/*    padding-bottom: 10px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.os-header-info {*/
/*    flex: 1;*/
/*}*/

/*.os-title {*/
/*    font-size: 2rem;*/
/*    margin: 0;*/
/*    color: #000;*/
/*}*/

/*.os-order-id {*/
/*    font-size: 1rem;*/
/*    color: #666;*/
/*}*/

/*.os-header-actions {*/
/*    display: flex;*/
/*    gap: 10px;*/
/*}*/

/*.os-btn {*/
/*    background-color: #000;*/
/*    color: #fff;*/
/*    border: none;*/
/*    padding: 10px 20px;*/
/*    font-size: 1rem;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*    transition: background-color 0.3s ease;*/
/*}*/

/*.os-btn:hover {*/
/*    background-color: #333;*/
/*}*/

/*!* Order Details Card *!*/
/*.os-order-details-card {*/
/*    border: 1px solid #ddd;*/
/*    border-radius: 6px;*/
/*    padding: 15px;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.os-order-details-card h2 {*/
/*    margin-top: 0;*/
/*    font-size: 1.5rem;*/
/*    color: #000;*/
/*}*/

/*.os-order-details-card p {*/
/*    margin: 5px 0;*/
/*}*/

/*!* Filter Section *!*/
/*.os-filter {*/
/*    margin-bottom: 20px;*/
/*}*/

/*.os-filter-row {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    flex-wrap: wrap;*/
/*}*/

/*.os-filter-info {*/
/*    flex: 1;*/
/*}*/

/*.os-filter-info h2 {*/
/*    margin: 0;*/
/*    font-size: 1.5rem;*/
/*    color: #000;*/
/*}*/

/*.os-filter-info p {*/
/*    margin: 5px 0;*/
/*    color: #666;*/
/*}*/

/*.os-filter-search {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    justify-content: flex-end;*/
/*    margin-top: 10px;*/
/*}*/

/*.os-search-group {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    max-width: 300px;*/
/*}*/

/*.os-search-icon {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 10px;*/
/*    transform: translateY(-50%);*/
/*    color: #666;*/
/*}*/

/*.os-search-input {*/
/*    width: 100%;*/
/*    padding: 10px 10px 10px 40px;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 4px;*/
/*}*/

/*!* Articles Grid *!*/
/*.os-articles {*/
/*    margin-top: 20px;*/
/*}*/

/*.os-no-articles {*/
/*    text-align: center;*/
/*    padding: 40px 0;*/
/*    font-size: 1.2rem;*/
/*    color: #666;*/
/*}*/

/*!* Article Card - Updated Clean Layout *!*/
/*.card.os-article-card {*/
/*    border: 1px solid #e0e0e0;*/
/*    height: 100%;*/
/*    background-color: #fff;*/
/*    cursor: pointer;*/
/*}*/

/*.os-card-header {*/
/*    background-color: #000;*/
/*    padding: 12px 15px;*/
/*}*/

/*.os-card-title {*/
/*    font-size: 1.1rem;*/
/*    margin: 0;*/
/*    color: #fff;*/
/*    font-weight: 500;*/
/*}*/

/*.os-card-subtitle {*/
/*    font-size: 0.85rem;*/
/*    margin: 4px 0 0;*/
/*    color: rgba(255, 255, 255, 0.85);*/
/*}*/

/*.os-card-body {*/
/*    padding: 15px;*/
/*}*/

/*.os-card-section {*/
/*    padding: 12px 0;*/
/*    border-bottom: 1px solid #eee;*/
/*}*/

/*.os-card-section:last-child {*/
/*    border-bottom: none;*/
/*}*/

/*.os-dimensions-row {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    font-size: 0.9rem;*/
/*    color: #333;*/
/*}*/

/*.os-quantity {*/
/*    color: #666;*/
/*}*/

/*.os-section-label {*/
/*    font-size: 0.85rem;*/
/*    text-transform: uppercase;*/
/*    color: #666;*/
/*    margin: 0 0 8px 0;*/
/*    font-weight: 600;*/
/*    letter-spacing: 0.3px;*/
/*}*/

/*.os-spec-row {*/
/*    display: flex;*/
/*    margin-bottom: 6px;*/
/*    font-size: 0.9rem;*/
/*}*/

/*.os-spec-row:last-child {*/
/*    margin-bottom: 0;*/
/*}*/

/*.os-spec-label {*/
/*    width: 45px;*/
/*    color: #666;*/
/*    font-weight: 500;*/
/*}*/

/*.os-spec-value {*/
/*    color: #333;*/
/*    flex: 1;*/
/*}*/

/*.os-image-container {*/
/*    margin-top: 15px;*/
/*    text-align: center;*/
/*}*/

/*.os-article-img {*/
/*    max-width: 100%;*/
/*    max-height: 200px;*/
/*    object-fit: contain;*/
/*}*/

/*.os-no-image {*/
/*    padding: 15px;*/
/*    background: #f8f8f8;*/
/*    border-radius: 4px;*/
/*}*/

/*.os-tooltip {*/
/*    display: inline-block;*/
/*    position: relative;*/
/*}*/

/*.os-tooltip-icon {*/
/*    color: #666;*/
/*    font-size: 1.1rem;*/
/*}*/

/*.os-tooltip-text {*/
/*    visibility: hidden;*/
/*    position: absolute;*/
/*    bottom: 125%;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    background: rgba(0, 0, 0, 0.8);*/
/*    color: #fff;*/
/*    padding: 5px 10px;*/
/*    border-radius: 4px;*/
/*    font-size: 0.75rem;*/
/*    white-space: nowrap;*/
/*    opacity: 0;*/
/*    transition: opacity 0.2s;*/
/*    z-index: 1;*/
/*}*/

/*.os-tooltip:hover .os-tooltip-text {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*}*/

/*!* Responsive *!*/
/*@media (max-width: 768px) {*/
/*    .os-header {*/
/*        flex-direction: column;*/
/*        align-items: flex-start;*/
/*    }*/

/*    .os-header-actions {*/
/*        width: 100%;*/
/*        display: flex;*/
/*        justify-content: space-between;*/
/*        margin-top: 10px;*/
/*    }*/

/*    .os-filter-row {*/
/*        flex-direction: column;*/
/*        align-items: flex-start;*/
/*        gap: 10px;*/
/*    }*/

/*    .os-filter-search {*/
/*        width: 100%;*/
/*        display: flex;*/
/*        justify-content: flex-start;*/
/*    }*/
/*}*/


/* Base Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    color: #333;
    margin: 0;
    padding: 0;
}

/* Order Summary Page */
.os-page {
    background-color: #fff;
    min-height: 100vh;
    padding: 40px 0;
}

.os-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}

.os-main {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
}

/* Header */
.os-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.os-header-info {
    flex: 1;
}

.os-title {
    font-size: 2rem;
    margin: 0;
    color: #000;
}

.os-order-id {
    font-size: 1rem;
    color: #666;
}

.os-header-actions {
    display: flex;
    gap: 10px;
}

.os-btn {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.os-btn:hover {
    background-color: #333;
}

/* Order Details Card */
.os-order-details-card {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 20px;
}

.os-order-details-card h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #000;
}

.os-order-details-card p {
    margin: 5px 0;
}

/* Filter Section */
.os-filter {
    margin-bottom: 20px;
}

.os-filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.os-filter-info {
    flex: 1;
}

.os-filter-info h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #000;
}

.os-filter-info p {
    margin: 5px 0;
    color: #666;
}

.os-filter-search {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.os-search-group {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.os-search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #666;
}

.os-search-input {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Articles Grid */
.os-articles {
    margin-top: 20px;
}

.os-no-articles {
    text-align: center;
    padding: 40px 0;
    font-size: 1.2rem;
    color: #666;
}

/* Article Card - Updated Layout */
.card.os-article-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 15px;
    height: 100%;
}

.card.os-article-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.os-card-header {
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid #eee;
}

.os-card-title-group {
    display: flex;
    flex-direction: column;
}

.os-card-title {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.os-card-subtitle {
    display: inline-block;
    font-size: 0.85rem;
    color: #fff;
    background-color: #000;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    margin-top: 0.5rem;
    align-self: flex-start;
}

.os-card-body {
    padding: 1rem;
}

.os-detail-section {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
}

.os-detail-section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.os-detail-label {
    display: block;
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
}

.os-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    margin-bottom: 0.35rem;
}

.os-detail-row:last-child {
    margin-bottom: 0;
}

.os-detail-key {
    color: #666;
    min-width: 45px;
}

.os-detail-value {
    color: #333;
    font-weight: 500;
}

.os-quantity {
    color: #666;
    font-weight: 500;
}

/* Image Container */
.os-image-container {
    margin-top: 1rem;
    padding-top: 1rem;
    text-align: center;
}

.os-article-img {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    border-radius: 4px;
}

.os-no-image {
    padding: 1rem;
    background: #f8f8f8;
    border-radius: 4px;
}

/* Tooltip */
.os-tooltip {
    display: inline-block;
    position: relative;
}

.os-tooltip-icon {
    color: #666;
    font-size: 1.1rem;
}

.os-tooltip-text {
    visibility: hidden;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.75rem;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 1;
}

.os-tooltip:hover .os-tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .os-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .os-header-actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .os-filter-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .os-filter-search {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}