/* Container for the tooltip image carousel */
.tooltip-carousel-container {
    position: relative;
    max-width: 100%; /* Ensure the carousel doesn't overflow */
    margin-top: 20px;
}

.tooltip-image-wrapper {
    text-align: center;
}

.tooltip-carousel-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a clean look */
}

.tooltip-carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.tooltip-carousel-arrow {
    cursor: pointer;
    font-size: 2.5rem; /* Increase the font size for better visibility */
    color: #333;
    padding: 15px; /* Increase padding for a larger clickable area */
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added subtle shadow for better contrast */
}

.tooltip-carousel-arrow:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.1); /* Slightly enlarge on hover */
}

.tooltip-left-arrow {
    left: 20px !important; /* Adjusted for better positioning */
    margin-left: 10px;
}

.tooltip-right-arrow {
    right: 20px; /* Adjusted for better positioning */
}
