.cabinet-qc-page {
    padding: 20px;
}

#dimensions-card {
    height: fit-content;
}

#dimensions-container {
    margin-top: 20px;
}

.dimensions-item {
    margin-bottom: 0px;
    margin-right: 40px;
}

#image-dimensions-container {
    justify-content: space-between;
    /*border: 1px solid black;*/

}

.pos-txt {
    font-size: 1.5rem;
    font-weight: bold;
}

.vertical-text {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    transform-origin: center center;
    height: 200px;
    font-weight: bold;
    position: absolute;
    left: -100px;
    top: 50%;
    width: 200px;
    margin-top: -100px;
    margin-left: 50px;
}

.card .cabinet-qc-card {
    margin-bottom: 20px;
    position: relative;
    padding-left: 50px;
}

.table th, .table td {
    vertical-align: middle;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.summary-title {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 15%;
}

.row.production,
.row.hardware,
.row.drawers {
    margin-top: 20px;
    position: relative;
}

.row.production .vertical-text,
.row.hardware .vertical-text,
.row.drawers .vertical-text {
    left: -50px;
}

.qc-download-dwg, .qc-view-pdf {
    /*color: black;*/
    text-decoration: none;
}

.qc-links {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .vertical-text {
        transform: rotate(0);
        height: auto;
        text-align: center;
        margin-bottom: 10px;
        position: static;
        width: auto;
        margin-top: 0;
        left: auto;
        top: auto;
    }

    .card {
        padding-left: 15px;
    }

    .summary-title {
        margin-left: 10%;
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}