.contact-container {
    background-color: #f8f9fa;
    padding: 80px 0;
}

.contact-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-heading {
    font-size: 36px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}

.contact-description {
    font-size: 18px;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

.contact-info {
    margin-bottom: 40px;
}

.contact-info p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

.contact-info i {
    margin-right: 10px;
    color: #000;
}

.contact-form .form-label {
    font-weight: bold;
    color: #000;
}

.contact-form .form-control {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #000;
    background-color: transparent;
    color: #000;
}

.contact-form .form-control:focus {
    box-shadow: none;
    border-color: #000;
}

.contact-form .btn-primary {
    background-color: #000;
    border-color: #000;
    border-radius: 0;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: bold;
}

.contact-form .btn-primary:hover {
    background-color: #333;
    border-color: #333;
}

.contact-form .fa-paperclip {
    margin-right: 5px;
}

.disclaimer {
    font-size: 14px;
    color: #555;
    text-align: center;
    margin-top: 20px;
}

.disclaimer a {
    color: #000;
    text-decoration: underline;
}

.disclaimer a:hover {
    color: #333;
    text-decoration: none;
}
