/* Base Styles for Desktop */
.image-carousel-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.image-container {
    max-width: 300px; /* Limiting max width to 300px for larger screens */
    width: 100%;
    height: auto;
}

.image-carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%; /* Make the controls fit the width of the image */
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-50%);
    pointer-events: none; /* Only arrows are clickable */

}

.carousel-arrow {
    pointer-events: all; /* Ensures arrows are clickable */
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.5); /* Default arrow color */
    cursor: pointer;
    user-select: none;
    transition: color 0.3s;
}

.carousel-arrow:hover {
    color: rgba(0, 0, 0, 0.8); /* Darken arrow color on hover */
}

.left-arrow {
    margin-left: 10px;
}

.right-arrow {
    margin-right: 10px;
}

/* Mobile Screen Adjustments */
@media (max-width: 576px) {
    .image-container {
        max-width: 39%; /* Make image take 90% of the screen width on smaller screens */
        width: 100%;
        height: auto;
        /*border: 1px black solid;*/
    }

    .image-carousel-controls {
        width: 92%; /* Ensure controls stretch the full width on mobile */
    }

    .carousel-arrow {
        font-size: 1.2rem; /* Slightly reduce the size of the arrows */
    }

    .left-arrow {
        margin-left: 5px; /* Reduce arrow margins for smaller screens */
    }

    .right-arrow {
        margin-right: 5px;
    }
}
