.vpo-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    background: #fff;
}

/* Header Styles */
.vpo-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #e5e7eb;
}

.vpo-header h1 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
}

.vpo-meta {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.vpo-meta-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.vpo-meta-item span {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #6b7280;
    letter-spacing: 0.05em;
}

.vpo-meta-item strong {
    font-size: 0.875rem;
    color: #111827;
}

.vpo-header-vendor {
    text-align: right;
}

.vpo-header-vendor h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
}

.vpo-role {
    font-size: 0.875rem;
    color: #6b7280;
}

/* Edit Panel */
.vpo-edit-panel {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    background: #f9fafb;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
}

.vpo-edit-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.vpo-edit-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.vpo-edit-group label {
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #374151;
    letter-spacing: 0.05em;
}

.vpo-edit-group.full-width {
    grid-column: 1 / -1;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

/* Input Field Style */
.vpo-input {
    padding: 0.5rem 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #111827;
    background: #fff;
}

.vpo-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 1px #2563eb;
}

/* Notes Field */
.vpo-notes-group {
    grid-column: 1 / -1;
    margin-top: 1rem;
}

.vpo-notes-field {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #111827;
    resize: vertical;
    min-height: 100px;
    font-family: inherit;
    background: white;
}

.vpo-notes-field:focus {
    outline: none;
    border-color: #808080;
    box-shadow: 0 0 0 1px #808080;
}

.vpo-notes-field::placeholder {
    color: #9ca3af;
}

/* Status Buttons */
.vpo-status-buttons {
    display: flex;
    gap: 0.5rem;
}

.vpo-status-btn {
    flex: 1;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    background: #fff;
    color: #374151;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.vpo-status-btn:hover {
    border-color: #d1d5db;
}

.vpo-status-btn.active {
    border-width: 2px;
}

.vpo-status-btn.active.paid {
    background: #dcfce7;
    border-color: #22c55e;
    color: #15803d;
}

.vpo-status-btn.active.pending {
    background: #fef9c3;
    border-color: #eab308;
    color: #854d0e;
}

.vpo-status-btn.active.overdue {
    background: #fee2e2;
    border-color: #ef4444;
    color: #b91c1c;
}

/* Amount Field */
.vpo-amount-field {
    position: relative;
}

.vpo-currency {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6b7280;
}

.vpo-amount-field input {
    width: 100%;
    padding: 0.5rem 0.75rem 0.5rem 1.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #111827;
}

.vpo-amount-field input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 1px #2563eb;
}

/* Save Button */
.vpo-save-btn {
    padding: 0.5rem 1.5rem;
    background: #111827;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    width: auto;
}

.vpo-save-btn:hover {
    background: #1f2937;
}

.vpo-save-btn:disabled {
    background: #9ca3af;
    cursor: not-allowed;
}

/* Center the save button */
.save-btn-group {
    display: flex;
    justify-content: center;
}

/* Content Grid */
.vpo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1.5rem;
}

/* Cards */
.vpo-card {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    overflow: hidden;
}

.vpo-card-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 1.25rem;
    background: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
}

.vpo-card-header h3 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
}

.vpo-card-body {
    padding: 1.25rem;
}

.vpo-data-item {
    padding: 1rem;
    background: #f9fafb;
    border-radius: 0.375rem;
    margin-bottom: 0.75rem;
}

.vpo-data-item:last-child {
    margin-bottom: 0;
}

.vpo-data-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vpo-data-key {
    font-size: 0.875rem;
    color: #000;
}

.vpo-data-value {
    font-size: 0.875rem;
    font-weight: 500;
    color: #000;
}

.vpo-data-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #e5e7eb;
}

.vpo-data-sub span {
    font-size: 0.75rem;
    color: #000;
}

.vpo-data-sub span:last-child {
    font-weight: 500;
    color: #000;
}

/* Redesigned Drawer Data Styles */
.drawer-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.drawer-card {
    flex: 1 1 200px;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.drawer-dimension,
.drawer-quantity {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.drawer-label {
    font-size: 0.85rem;
    color: #6b7280;
}

.drawer-value {
    font-size: 0.85rem;
    font-weight: 600;
    color: #111827;
}

/* Assembly Vendor Styles */
.assembly-detail {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e5e7eb;
}

.assembly-detail:last-child {
    border-bottom: none;
}

.assembly-label {
    font-size: 0.875rem;
    color: #6b7280;
}

.assembly-value {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
}

/* Alert */
.vpo-alert {
    padding: 0.75rem 1rem;
    background: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 0.375rem;
    color: #b91c1c;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
}

/* Loading */
.vpo-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .vpo-container {
        padding: 1.5rem;
    }

    .vpo-edit-panel {
        grid-template-columns: 1fr;
    }

    .save-btn-group {
        margin-top: 1rem;
    }
}

@media (max-width: 768px) {
    .vpo-container {
        padding: 1rem;
    }

    .vpo-header {
        flex-direction: column;
        gap: 1.5rem;
    }

    .vpo-meta {
        flex-direction: column;
        gap: 1rem;
    }

    .vpo-edit-panel {
        grid-template-columns: 1fr;
    }

    .save-btn-group {
        margin-top: 1rem;
    }

    .vpo-grid {
        grid-template-columns: 1fr;
    }
}

/* Print Styles */
@media print {
    .vpo-container {
        padding: 0;
    }

    .vpo-edit-panel {
        display: none;
    }

    .vpo-card {
        break-inside: avoid;
    }
}
