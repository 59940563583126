.aboutus-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.aboutus-sb {
    width: fit-content;
}

.sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
    align-self: flex-start;
    padding: 10px;
    background-color: #f8f9fa;
    border-right: 1px solid #ccc;
    height: calc(100vh - 0px);
    overflow-y: auto;
}

.sidebar-list {
    list-style-type: none;
    padding: 0;
}

.sidebar-link {
    color: black;
    text-decoration: none;
    display: block;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.3s;
}

.sidebar-link:hover {
    background-color: #e9ecef;
}

.content {
    padding: 20px;
    flex-grow: 1;
    background-color: white;
}

section {
    margin-bottom: 40px;
}

h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

h3 {
    font-size: 1.5rem;
    margin-top: 20px;
}

p, ol, ul {
    font-size: 1rem;
    line-height: 1.5;
}

ol {
    padding-left: 20px;
}

ul {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .sidebar {
        position: static;
        width: 100%;
        height: auto;
        border-right: none;
        border-bottom: 1px solid #ccc;
    }

    .content {
        padding: 10px;
    }
}
