.mat-hware-container {
    max-width: 100%;
    padding: 20px;
}

#mat-hware-summary-title {
    text-align: center;
    margin-top: 20px;
}

.download-btn {
    text-align: right;
    margin-right: 30px;
}

.mat-hware-tables {
    margin-bottom: 30px;
}

.mat-hware-titles {
    margin-top: 20px;
    text-align: center;
}

.table {
    margin-bottom: 0;
}

.table th,
.table td {
    vertical-align: middle;
}

@media (max-width: 767px) {
    .col-md-6 {
        margin-bottom: 20px;
    }
}

.col-md-6 {
    margin-bottom: 30px;
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Adjust as needed to fit your layout */
}

.spinner-border {
    width: 3rem;
    height: 3rem;
    border-width: 0.25em;
}
