#amc-container {
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 2rem;
}

#amc-main-title {
    color: #212529;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.amc-card {
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    height: 100%;
    position: relative;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.amc-section-title {
    color: #212529;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.amc-step-number {
    background-color: #212529;
    color: #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
}

.amc-search-box {
    position: relative;
    margin-bottom: 1rem;
}

.amc-search-box input {
    padding: 0.75rem 1rem;
    border: 2px solid #dee2e6;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
}

.amc-search-box input:focus {
    border-color: #212529;
    box-shadow: none;
}

/* User Grid Styles */
.amc-user-grid {
    display: grid;
    gap: 0.75rem;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.amc-user-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border: 2px solid #dee2e6;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
}

.amc-user-card:hover {
    border-color: #212529;
    background-color: #f8f9fa;
}

.amc-user-card.amc-selected {
    background-color: #212529;
    color: #ffffff;
    border-color: #212529;
}

.amc-user-initial {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #212529;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 1rem;
    flex-shrink: 0;
}

.amc-user-card.amc-selected .amc-user-initial {
    background-color: #ffffff;
    color: #212529;
}

.amc-user-info {
    flex: 1;
    min-width: 0;
}

.amc-user-name {
    font-weight: 600;
    margin-bottom: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.amc-user-email {
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.amc-user-card.amc-selected .amc-user-email {
    color: #dee2e6;
}

/* Article Grid Styles */
.amc-article-grid {
    display: grid;
    gap: 0.75rem;
    max-height: 500px;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.amc-article-card {
    padding: 1rem;
    border: 2px solid #dee2e6;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
}

.amc-article-card:hover {
    border-color: #212529;
    background-color: #f8f9fa;
}

.amc-article-card.amc-selected {
    background-color: #212529;
    color: #ffffff;
    border-color: #212529;
}

.amc-article-serial {
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.amc-article-description {
    font-size: 0.875rem;
    color: #6c757d;
    line-height: 1.4;
}

.amc-article-card.amc-selected .amc-article-description {
    color: #dee2e6;
}

/* Selected Check Mark */
.amc-selected-check {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    font-weight: bold;
    font-size: 0.875rem;
}

/* Assignment Summary */
.amc-assignment-summary {
    font-size: 1.1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #f8f9fa;
    margin-bottom: 1.5rem;
}

.amc-text-center {
    text-align: center;
}

/* Button Styles */
.amc-submit-button {
    background-color: #212529;
    color: #ffffff;
    padding: 0.875rem 2rem;
    font-weight: 500;
    border: none;
    transition: all 0.2s ease;
    font-size: 1.1rem;
    border-radius: 0.5rem;
    width: auto;
    min-width: 200px;
}

.amc-submit-button:hover:not(:disabled) {
    background-color: #1a1e21;
    transform: translateY(-1px);
}

.amc-submit-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

/* Success Message */
.amc-success-message {
    font-size: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: #d4edda;
    color: #155724;
    border-radius: 0.5rem;
    border: 1px solid #c3e6cb;
}

/* Scrollbar Styles */
.amc-user-grid::-webkit-scrollbar,
.amc-article-grid::-webkit-scrollbar {
    width: 8px;
}

.amc-user-grid::-webkit-scrollbar-track,
.amc-article-grid::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.amc-user-grid::-webkit-scrollbar-thumb,
.amc-article-grid::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.amc-user-grid::-webkit-scrollbar-thumb:hover,
.amc-article-grid::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Loading States */
.amc-loading-spinner {
    color: #212529;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .amc-user-grid,
    .amc-article-grid {
        max-height: 400px;
    }

    .amc-section-title {
        font-size: 1rem;
    }

    .amc-card {
        padding: 1rem;
    }

    .amc-submit-button {
        width: 100%;
        padding: 0.75rem 1rem;
    }

    .amc-assignment-summary {
        font-size: 1rem;
        padding: 0.75rem;
    }
}

/* Empty State Styles */
.amc-empty-state {
    text-align: center;
    padding: 2rem;
    color: #6c757d;
}

/* Error State Styles */
.amc-error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    border: 1px solid #f5c6cb;
    font-size: 0.875rem;
}

/* Focus States */
.amc-user-card:focus,
.amc-article-card:focus {
    outline: none;
    border-color: #212529;
    box-shadow: 0 0 0 2px rgba(33, 37, 41, 0.2);
}

/* Transition Styles */
.amc-user-card,
.amc-article-card,
.amc-submit-button,
.amc-search-box input {
    transition: all 0.2s ease-in-out;
}