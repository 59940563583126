/*body {*/
/*    background-color: #f8f9fa;*/
/*}*/

/*.container {*/
/*    max-width: 960px;*/
/*}*/

/*h1 {*/
/*    font-size: 2.5rem;*/
/*    font-weight: bold;*/
/*}*/

/*h2 {*/
/*    font-size: 1.8rem;*/
/*    font-weight: bold;*/
/*    margin-bottom: 1rem;*/
/*}*/

/*p {*/
/*    font-size: 1.2rem;*/
/*}*/

/*.file-item {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-bottom: 1rem;*/
/*}*/

/*.invoice-amt-paid {*/
/*    font-style: italic;*/
/*    font-size: 1rem;*/
/*}*/

/*.testing8 {*/
/*    font-size: .8rem;*/
/*    color: red*/
/*}*/

/*.file-name {*/
/*    flex: 1;*/
/*    margin-right: 1rem;*/
/*}*/

/*.btn-dark {*/
/*    background-color: #000;*/
/*    border-color: #000;*/
/*    color: #fff;*/
/*}*/

/*.btn-dark:hover {*/
/*    background-color: #333;*/
/*    border-color: #333;*/
/*}*/

/*.invoice-container {*/
/*    background-color: #fff;*/
/*    border-radius: 5px;*/
/*    padding: 20px;*/
/*    margin-bottom: 20px;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*}*/

/*.invoice-header {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.invoice-header-left {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

/*.company-info h2 {*/
/*    font-size: 1.8rem;*/
/*    margin-bottom: 5px;*/
/*}*/

/*.company-info p {*/
/*    font-size: 1rem;*/
/*    margin-bottom: 2px;*/
/*}*/

/*.invoice-details h3 {*/
/*    font-size: 1.5rem;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.invoice-details p {*/
/*    font-size: 1rem;*/
/*    margin-bottom: 2px;*/
/*}*/

/*.invoice-body {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.bill-to,*/
/*.ship-to {*/
/*    flex: 1;*/
/*}*/

/*.bill-to h4,*/
/*.ship-to h4 {*/
/*    font-size: 1.2rem;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.bill-to p,*/
/*.ship-to p {*/
/*    font-size: 1rem;*/
/*    margin-bottom: 2px;*/
/*}*/

/*.invoice-items {*/
/*    !*margin-bottom: 20px;*!*/
/*}*/

/*.invoice-items table {*/
/*    width: 100%;*/
/*    border-collapse: collapse;*/
/*}*/

/*.invoice-items th,*/
/*.invoice-items td {*/
/*    padding: 10px;*/
/*    text-align: left;*/
/*    border-bottom: 1px solid #ddd;*/
/*}*/

/*.invoice-items th.text-right,*/
/*.invoice-items td.text-right {*/
/*    text-align: right;*/
/*}*/

/*.invoice-items th {*/
/*    background-color: #f2f2f2;*/
/*    font-weight: bold;*/
/*}*/

/*.invoice-total {*/
/*    font-weight: bold;*/
/*    font-size: 1.2rem;*/
/*    margin-bottom: 20px;*/
/*    text-align: right;*/
/*}*/

/*.invoice-footer {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: flex-start;*/
/*    text-align: center;*/
/*}*/

/*.not-included {*/
/*    text-align: left;*/
/*    margin-right: 20px;*/
/*}*/

/*.not-included p {*/
/*    font-size: 1.2rem;*/
/*    font-weight: bold;*/
/*    margin-bottom: 5px;*/
/*}*/

/*.not-included ul {*/
/*    list-style-type: disc;*/
/*    margin-left: 20px;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.not-included li {*/
/*    font-size: 1rem;*/
/*    margin-bottom: 2px;*/
/*}*/

/*#pay-now-btn {*/
/*    margin-left: 5px;*/
/*    margin-right: 10px;*/

/*}*/

/*.pay-btn-div {*/
/*    text-align: center;*/
/*}*/

/*.status-bar {*/
/*    display: flex;*/
/*    margin-top: 10px;*/
/*}*/

/*.status-bar-segment {*/
/*    flex: 1;*/
/*    height: 10px;*/
/*    margin-right: 5px;*/
/*    background-color: #e0e0e0;*/
/*    border-radius: 3px;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.status-bar-segment.filled {*/
/*    background-color: #28a745;*/

/*}*/

/*.status-label {*/
/*    margin-right: 25px;*/
/*}*/

/*.invoice-img {*/
/*    max-width: 100px;*/
/*    max-height: 100px;*/
/*}*/

/*.testing {*/
/*    !*max-width: 50px;*!*/
/*    !*border: none;*!*/
/*    !*padding: 500px;*!*/
/*}*/

/*.invoice-td-text {*/
/*    white-space: normal;*/
/*    word-wrap: break-word;*/
/*    overflow-wrap: break-word;*/
/*    word-break: break-word;*/
/*    max-width: 250px; !* Adjust this value as needed *!*/
/*    font-size: .97em;*/
/*}*/

/*.terms-section {*/
/*    max-width: 100%;*/
/*    text-align: center;*/
/*    margin-left: auto;*/
/*    !*font-size: ;*!*/
/*    line-height: 1.4;*/
/*}*/

/*.terms-section p {*/
/*    margin: 0;*/
/*    !*margin-top: 20px;*!*/
/*    padding: 0;*/
/*    font-size: 14px;*/

/*}*/

/*@media (max-width: 768px) {*/
/*    h1 {*/
/*        font-size: 2rem;*/
/*    }*/

/*    h2 {*/
/*        font-size: 1.5rem;*/
/*    }*/

/*    p {*/
/*        font-size: 1rem;*/
/*    }*/

/*    .invoice-header {*/
/*        flex-direction: column;*/
/*    }*/

/*    .invoice-body {*/
/*        flex-direction: column;*/
/*    }*/

/*    .bill-to, .ship-to {*/
/*        margin-bottom: 20px;*/
/*    }*/

/*    .invoice-footer {*/
/*        flex-direction: column;*/
/*        align-items: center;*/
/*    }*/

/*    .not-included {*/
/*        margin-right: 0;*/
/*        margin-bottom: 20px;*/
/*    }*/

/*    .status-label {*/
/*        margin-top: 10px;*/
/*    }*/
/*}*/


body {
    background-color: #f8f9fa;
}

.container {
    max-width: 960px;
}

h1 {
    font-size: 2.5rem;
    font-weight: bold;
}

h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

p {
    font-size: 1.2rem;
}

.file-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.invoice-amt-paid {
    font-style: italic;
    font-size: 1rem;
}

.testing8 {
    font-size: 0.8rem;
    color: red;
}

.file-name {
    flex: 1;
    margin-right: 1rem;
}

.btn-dark {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.btn-dark:hover {
    background-color: #333;
    border-color: #333;
}

.invoice-container {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.invoice-header-left {
    display: flex;
    flex-direction: column;
}

.company-info h2 {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.company-info p {
    font-size: 1rem;
    margin-bottom: 2px;
}

.invoice-details h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.invoice-details p {
    font-size: 1rem;
    margin-bottom: 2px;
}

.invoice-body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.bill-to,
.ship-to {
    flex: 1;
}

.bill-to h4,
.ship-to h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.bill-to p,
.ship-to p {
    font-size: 1rem;
    margin-bottom: 2px;
}

.invoice-items table {
    width: 100%;
    border-collapse: collapse;
}

.invoice-items th,
.invoice-items td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.invoice-items th.text-right,
.invoice-items td.text-right {
    text-align: right;
}

.invoice-items th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.invoice-total {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: right;
}

.invoice-total .offer-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
}

.invoice-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 20px;
}

.included-options,
.not-included-options {
    width: auto;
    margin-bottom: 20px;
}

/* Underline for section headings with constrained width */
.included-options h4,
.not-included-options h4 {
    border-bottom: 2px solid #000;
    display: inline-block;
    max-width: 200px;
    padding-bottom: 4px;
    margin-bottom: 10px;
}

.warning-text {
    color: red;
    font-style: italic;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.status-bar {
    display: flex;
    margin-top: 10px;
}

.status-bar-segment {
    flex: 1;
    height: 10px;
    margin-right: 5px;
    background-color: #e0e0e0;
    border-radius: 3px;
    margin-bottom: 10px;
}

.status-bar-segment.filled {
    background-color: #28a745;
}

.status-label {
    margin-right: 25px;
}

.invoice-img {
    max-width: 100px;
    max-height: 100px;
}

.invoice-td-text {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 250px;
    font-size: 0.97em;
}

.terms-section {
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    line-height: 1.4;
}

.terms-section p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.pay-btn-div {
    text-align: center;
}

#pay-now-btn {
    margin-left: 5px;
    margin-right: 10px;
}

/* Style for the red asterisk indicating changes */
.changed-asterisk {
    color: red;
    font-size: 1.25rem;
}

@media (max-width: 768px) {
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }

    .invoice-header {
        flex-direction: column;
    }

    .invoice-body {
        flex-direction: column;
    }

    .bill-to, .ship-to {
        margin-bottom: 20px;
    }

    .invoice-footer {
        flex-direction: column;
        align-items: center;
    }

    .included-options, .not-included-options {
        width: 100%;
        margin-bottom: 20px;
    }

    .status-label {
        margin-top: 10px;
    }
}
