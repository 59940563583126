.footer {
    bottom: 0;
    width: 100%;
    background-color: #f8f9fa;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer .footer-link {
    color: black;
    text-decoration: none;
}

.footer .footer-link:hover {
    text-decoration: underline;
}
