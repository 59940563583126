/* Root container */
.it-internal-tools {
    background-color: #f9f9f9;
    color: #333333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Container styles */
.it-tools-container {
    padding: 20px;
}

/* Title styles */
.it-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222222;
}

/* Description styles */
.it-description {
    font-size: 1.2rem;
    color: #555555;
    margin-bottom: 20px;
}

/* Card styles */
.it-tool-card {
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.it-tool-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Icon styles */
.it-icon-container {
    background-color: #333333;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.it-tool-icon {
    color: #ffffff;
}

/* Tool title styles */
.it-tool-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 15px;
    color: #222222;
}

/* Tool description styles */
.it-tool-description {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 20px;
}

/* Button styles */
.it-tool-button {
    background-color: #333333;
    color: #ffffff;
    border: none;
    font-size: 1rem;
    padding: 10px 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.it-tool-button:hover {
    background-color: #000000;
    transform: scale(1.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .it-tool-card {
        margin-bottom: 20px;
    }

    .it-title {
        font-size: 2rem;
    }

    .it-description {
        font-size: 1rem;
    }
}
