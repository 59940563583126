.drawing-tool-container {
    padding: 20px;
    background-color: #f8f9fa;
    min-height: 100vh;
    min-width: 800px !important;
}

.drawing-tool-controls {
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drawing-tool-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.cabinet-properties-card {
    display: flex;
}

.drawing-tool-stage {
}

.drawing-tool-cabinet-menu {
    width: auto;
    min-width: 280px;
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cabinet-menu-inputs-container {
    display: flex;
    gap: 1rem;
    padding-bottom: 1.5rem;
}

.cabinet-dimension-group {
    position: relative;
    flex: 1;
}

.cabinet-dimension-label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    color: #495057;
}

.cabinet-dimension-input {
    width: 100% !important;
    min-width: 80px;
}

.cabinet-dimension-error {
    font-size: 0.75rem;
    color: #dc3545;
    margin-top: 0.25rem;
    white-space: nowrap;
    background-color: white;
    padding: 0.25rem 0;
    z-index: 1;
}

.cabinet-properties-button-div {
    display: flex;
    flex-direction: row-reverse;
}

.update-cabinet-button {
    margin-left: 3px;
}

.cabinet-return-button {
    margin-left: 0.5rem;
    align-self: flex-end;
}

.cart-inventory-items-card {
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-inventory-items-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.cart-inventory-items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
}

.cart-inventory-item {
    cursor: grab;
    user-select: none;
    border: none;
}

.cart-inventory-item:active {
    cursor: grabbing;
}

.cart-inventory-item-rectangle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #333;
    overflow: hidden;
}

.cart-inventory-item-text {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.save-chngs-btn {
    margin-left: 5px;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .drawing-tool-container {
        padding: 10px;
    }

    .drawing-tool-controls {
        margin-bottom: 20px;
    }

    .drawing-tool-stage-wrapper {
        min-height: 400px;
    }

    .drawing-tool-cabinet-menu {
        min-width: 240px;
    }
}
