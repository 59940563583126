/* Container for the entire tooltip explanation */
.tooltip-explain-container {
    padding: 20px;
    background-color: #f8f9fa; /* Light background for contrast */
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a clean look */
}

/* Title styling for the tooltip field */
.tooltip-explain-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333; /* Matching preferred color scheme */
    margin-bottom: 15px;
}

/* Content styling for the tooltip explanation */
.tooltip-explain-text {
    font-size: 1rem;
    color: #555; /* Slightly lighter than the title for contrast */
    line-height: 1.6;
}

/* Placeholder text for when no data is available */
.tooltip-no-data {
    font-size: 1.2rem;
    color: #888;
    text-align: center;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Style for the image */
.tooltip-image {
    max-width: 100%; /* Ensure the image is responsive */
    /*height: auto;*/
    border-radius: 8px; /* Optional: add slight rounding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a clean look */
}
