/*!* Container *!*/
/*.vc-container {*/
/*    padding: 20px;*/
/*    background-color: #fff;*/
/*    color: #000;*/
/*}*/

/*!* Title *!*/
/*.vc-title {*/
/*    font-size: 24px;*/
/*    font-weight: bold;*/
/*    margin-bottom: 20px;*/
/*}*/

/*!* Loading and error messages *!*/
/*.vc-loading,*/
/*.vc-error,*/
/*.vc-no-orders {*/
/*    font-size: 16px;*/
/*    font-weight: 500;*/
/*    color: #333;*/
/*}*/

/*.vc-error {*/
/*    color: red;*/
/*}*/

/*!* Responsive Table *!*/
/*.vc-table-responsive {*/
/*    overflow-x: auto;*/
/*}*/

/*!* Table *!*/
/*.vc-table {*/
/*    width: 100%;*/
/*    border-collapse: collapse;*/
/*}*/

/*.vc-table th,*/
/*.vc-table td {*/
/*    padding: 12px;*/
/*    text-align: left;*/
/*    border-bottom: 1px solid #ddd;*/

/*}*/

/*.vc-table th {*/
/*    background-color: #000;*/
/*    color: #fff;*/
/*}*/

/*!* Alternate Row Colors *!*/
/*.vc-table tr:nth-child(even) {*/
/*    background-color: #f8f8f8;*/
/*}*/

/*!* Clickable Row *!*/
/*.vc-table-row {*/
/*    cursor: pointer;*/
/*    transition: background-color 0.3s ease-in-out;*/
/*}*/

/*.vc-table tbody tr.vc-table-row:hover {*/
/*    background-color: #808080;*/
/*    color: #fff; !* Adding this to ensure text is visible on gray background *!*/
/*}*/


/*!* Alternate Row Colors *!*/
/*.vc-table > tbody > tr:nth-child(even) {*/
/*    background-color: #f8f8f8;*/
/*}*/

/*!* Clickable Row *!*/
/*.vc-table > tbody > tr.vc-table-row {*/
/*    cursor: pointer;*/
/*    transition: background-color 0.3s ease-in-out;*/
/*}*/

/*!* Extra specific hover style *!*/
/*.vc-table > tbody > tr.vc-table-row:hover,*/
/*.vc-table > tbody > tr.vc-table-row:hover td {*/
/*    background-color: #808080 !important;*/
/*    color: #fff !important;*/
/*    transition: 0.2s ease-in-out;*/
/*}*/


/* Container */
.vc-container {
    padding: 20px;
    background-color: #fff;
    color: #000;
}

/* Title */
.vc-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Filters */
.vc-filters {
    margin-bottom: 20px;
}

.vc-filter-group {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.vc-search-input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    width: 200px;
}

.vc-search-input:focus {
    outline: none;
    border-color: #666;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

/* Loading and error messages */
.vc-loading,
.vc-error,
.vc-no-orders {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.vc-error {
    color: red;
}

/* Responsive Table */
.vc-table-responsive {
    overflow-x: auto;
}

/* Table */
.vc-table {
    width: 100%;
    border-collapse: collapse;
}

.vc-table th,
.vc-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.vc-table th {
    background-color: white;
    color: black;
}

/* Super specific status selectors */
.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-pending,
.vc-container .vc-table-responsive .vc-table tbody tr.status-pending td {
    background-color: #ffeeba !important;
    transition: background-color 0.3s ease-in-out;
}

.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-paid,
.vc-container .vc-table-responsive .vc-table tbody tr.status-paid td {
    background-color: #c3e6cb !important;
    transition: background-color 0.3s ease-in-out;
}

.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-overdue,
.vc-container .vc-table-responsive .vc-table tbody tr.status-overdue td {
    background-color: #f5c6cb !important;
    transition: background-color 0.3s ease-in-out;
}

/* Hover states with maximum specificity */
.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-pending:hover,
.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-pending:hover td {
    background-color: #ffdf7e !important;
}

.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-paid:hover,
.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-paid:hover td {
    background-color: #95d0a2 !important;
}

.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-overdue:hover,
.vc-container .vc-table-responsive .vc-table tbody tr.vc-table-row.status-overdue:hover td {
    background-color: #efa2ab !important;
}

/* Base clickable row style */
.vc-table-row {
    cursor: pointer;
}

/* Additional specificity for edge cases */
html body .vc-container .vc-table-responsive .vc-table tbody tr[class*="status-"] {
    background-color: inherit;
}

/* Force color override */
html body .vc-container .vc-table-responsive .vc-table tbody tr[class*="status-"] td {
    color: #000 !important;
}