.custom-navbar {
    background-color: #000;
    padding-left: 20px;
    padding-right: 20px;
    /*height: 80px;*/
}

.custom-navbar .navbar-brand {
    font-weight: bold;
    color: #fff;
    padding-left: 20px;
    font-size: 30px;
}

.custom-navbar .navbar-nav .nav-link {
    padding-right: 20px;
    color: #fff;
    font-size: 1.17em;
}

.custom-navbar .nav-link:hover {
    color: #f8f9fa;
}

.custom-dropdown .dropdown-menu {
    background-color: black;
    text-align: right;
}

.custom-dropdown .dropdown-menu .dropdown-item {
    color: white;
    text-align: right;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #333;
}

.nav-cart-link {
    position: relative;
}

.cart-quantity-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 5px;
    font-size: 12px;
    transform: translate(25%, -25%);
}

@media (max-width: 992px) {
    .custom-navbar .navbar-toggler {
        border: 2px solid white;
        background-color: transparent;
        width: 40px;
        height: 34px;
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 5px;
    }

    .custom-navbar .navbar-toggler span {
        background-color: white;
        height: 2px;
        width: 25px;
    }

    .custom-navbar .navbar-toggler:focus {
        outline: none;
    }

    .custom-dropdown .dropdown-menu {
        position: absolute;
        right: 0;
        text-align: right;
        left: auto;
    }

    .navbar-collapse {
        text-align: right;
    }

    .nav-cart-link {
        display: inline-block;
        position: relative;
    }

    .cart-quantity-badge {
        position: absolute;
        top: -5px;
        right: -5px;
        transform: none;
    }

    .navbar-nav {
        align-items: flex-end;
    }

    .nav-link {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .custom-navbar .navbar-collapse {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        background-color: #000;
        padding: 10px;
    }

    .custom-navbar .navbar-nav {
        flex-direction: column;
    }

    .custom-navbar .nav-link {
        padding: 10px 0;
    }
}